import { Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { interval } from "rxjs";
import { GenericHelper } from "src/app/util/generic-helpers";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    updatePending = false;
    versionEnd: string;
    versionApp: string;
    hasUpdate = false;

    constructor(
        private swUpdate: SwUpdate,
    ) {         
    }

    ngOnInit(): void {                                
        this.versionEnd = environment.commons.versionApp;
        this.versionApp = localStorage.getItem('versionApp');
        if (this.swUpdate.isEnabled) {
            interval(60000).subscribe(() => this.swUpdate.checkForUpdate()
                .then(() => {
                    this.updatePending = JSON.parse(localStorage.getItem('updatePending'));
                    if(GenericHelper.isNullOrEmpty(this.updatePending)){
                        this.updatePending = true;
                    }        
                    if(this.versionEnd !== this.versionApp && this.updatePending) {
                        this.displayUpdate();
                    }
                }));
        }
        this.swUpdate.available.subscribe(() => {
            this.hasUpdate = true;
        });
    }

    ngOnDestroy(): void {
        localStorage.removeItem('updatePending');
    }

    displayUpdate() {
        Swal.fire({
            html: 'La web de Instore tiene una nueva versión. ¿Deseas iniciar la actualización?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#6d837a',
            cancelButtonColor: '#f5705c',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Mas tarde',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        }).then((result) => {
            if (result.value) {
                console.log("runing update");
                this.swUpdate.activateUpdate().then(async () => {
                    console.log("activateUpdate");
                    localStorage.setItem('versionApp', this.versionEnd);
                    localStorage.setItem('updatePending', JSON.stringify(false));
                    await this.clearCache();
                    await this.unregister();
                    document.location.reload();
                });
            } else {
                console.log("pending update");
                localStorage.setItem('updatePending', JSON.stringify(false));
            }
        });
    }

    async unregister() {
		let registration = await navigator.serviceWorker.ready;
		await registration.unregister();
	}

    async clearCache() {
        const cacheNames = await self.caches.keys();
		cacheNames.forEach((cacheName) => {
			caches.delete(cacheName);
		});
	}
}