import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit {

  tenantId: string;
  storeCode: string;
  path: string;
  others: number;
  returnChange: number;
  technicalService: number;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.path = this.route.snapshot.paramMap.get('path');
    this.others = environment.commons.service.OTHERS;
    this.returnChange = environment.commons.service.RETURN;
    this.technicalService = environment.commons.service.TECHNICAL_SERVICE;
  }

  othersRequest(){
    localStorage.setItem("option",this.others.toString());
    this.router.navigate([`/withdraws/authentication/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  changeReturn(){
    localStorage.setItem("option",this.returnChange.toString());
    this.router.navigate([`/withdraws/authentication/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  serviceTecnic(){
    localStorage.setItem("option",this.technicalService.toString());
    this.router.navigate([`/withdraws/authentication/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  withdraw(){
    this.router.navigate([`/withdraws/login/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  backWelcome() {
    this.router.navigate([`withdraws/welcome/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }
}
