<mat-dialog-content>
    <button mat-mini-fab type="button" class="modal-close-button p-2" aria-label="Close" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
    <mat-card>
        <mat-card-content>
            <div class="modal_container p-6">
                <div class="modal_container__header">
                    <h1 class="title m-0">¡Listo!</h1>
                    <h2 class="title m-0">Tu retiro ha sido validado</h2>
                    <p class="message pt-2 m-0">{{ salesNote }}</p>
                </div>
                <div class="modal_container__content">
                    <img src="./assets/image/img__shopping_cart.gif" style="width: 7.5rem;">
                    <h3 class="thankyou_text pb-4 m-0">¡Gracias por tu preferencia!</h3>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>