import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-updates-notification',
  templateUrl: './updates-notification.component.html',
  styleUrls: ['./updates-notification.component.scss']
})
export class UpdatesNotificationComponent implements OnInit {

  updateAvailable$: Observable<boolean>;
  closed$ = new Subject<void>();
  fullView: boolean = false;
  updatedLater: boolean = false;

  constructor(private updates: SwUpdate) {
    console.log('app-updates-notification');
    this.updateAvailable$ = merge(
      of(false),
      this.updates.available.pipe(map(() => true)),
      this.closed$.pipe(map(() => false)),
    );
    
    this.updateAvailable$.subscribe((value: boolean) => {
      console.log('value', value);
      if (value) {
        this.applyUpdates();
      }
    });

    this.updates.activated.subscribe(resp => {
      console.log('App is updated', resp);
    });
  }

  ngOnInit(): void {
  }

  applyUpdates() {
    document.location.reload();
  }

}
