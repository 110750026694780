export class Order {
  constructor(
    public orderId: string,
    public op: string,
    public documentNumber: string,
    public customerName: string,
    public receptorDocumentNumber: string,
    public receptor: string,
    public dateDelivery: string,
    public listProduct : Product[],
    public checked: boolean,
    public sellCompany: string,
    public customerEmail: string,
    public customerPhone: string
  ){ }
}

export class Product {
  constructor(
    public idProduct: any,
    public quantity: string,
    public priceUnit: string,
    public productCode: string,
    public productDescription: string,
    public location: string,
    public dispatchStatus: string,
    public lpnNumber: string,
    public dispatchNumber: string,
    public checked: boolean,
    public saleNoteNumber:string,
    public orderId:number,
    public measurable:string,
    public unitMeasurement: string
  ){}
}

export class Data {
  constructor(
    public data: Order [],
  ){}
}

export class User {
  constructor(
    public dni: string,
    public name: string,
  ) { }
}

export class OrderAttention {
  constructor(public op: string,
    public service: number,
    public documentNumber: string,
    public customerName: string,
    public orderId: string,
    public dateDelivery: string,
    public listProduct : Product[],
    public receptorDocumentNumber: string,
    public receptor: string,
    public sellCompany: string,
    public customerEmail: string,
    public customerPhone: string
    ){

  }
}

export interface Order {
	id: number,
	op: string,
	username: string,
	documentNumber: string,
	customerName: string,
	service: string,
	timeArrival: string,
	startTime: string,
	endTime: string,
	status: string,
}