<div class="h-full">
    <header-mobile [storeCode]="storeCode" [companyCode]="companyCode" (onRedirectTo)="goHome();"></header-mobile>
    <div class="container__body">
        <div class="menu p-4">
            <ng-container *ngFor="let option of menu">
                <div class="menu__container flex flex-col">
                    <span class="menu__text pb-4">{{ option.name }}</span> <!--Pedidos pendientes-->
                    <ng-container *ngFor="let submenu of option.submenu">
                        <button *ngIf="!loading" class="option my-4 px-0" (click)="submenu.goTo()" [disabled]="!submenu.enabled">
                            <div class="option__name" [matTooltip]="getTooltipText(submenu)" matTooltipClass="below">
                                <img [src]="submenu.enabled ? submenu.icon : submenu.iconDisabled">
                                <span class="option__text">{{ submenu.name }}</span>
                            </div>
                            <img [src]="submenu.enabled ? './assets/icon/menu__arrow_forward.svg' : './assets/icon/menu__arrow_forward_disabled.svg'" class="pl-3">
                        </button>
                        <div *ngIf="loading" class="option_loading py-4">
                            <div class="is_loading"></div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
