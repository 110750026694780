import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Order, OrderAttention, Product as ProductOrder } from '../../models/models';
import { OrderService } from '../../../services/order.service';
import { OrderFirebaseService } from 'src/app/services/order-firebase.service';
import { OrderMobile, Product } from '../../mobile/models/models';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  tenantId: string;
  storeCode: string;
  path: string;
  documentNumber: string;

  storeName: string = '';
  customerFirstName: string = '';
  authorizedPersonFirstName: string = '';
  authorizedPersonDocumentNumber: string = '';

  orders: OrderMobile[] = [];
  selectedOrders: OrderMobile[] = [];
  serviceWithDraw: any;
  registerUser: string;

  disableAll: boolean = false;
  isLoading: boolean = true;

  constructor(private orderFirebaseService: OrderFirebaseService,
    private router: Router,
    private route: ActivatedRoute,
    private orderApiService: OrderService) { }

  ngOnInit(): void {
    this.serviceWithDraw = environment.commons.service.WITHDRAW;
    this.documentNumber = localStorage.getItem('documentNumber');
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.path = this.route.snapshot.paramMap.get('path');
    this.registerUser = localStorage.getItem("userUse");
    this.validateLogin();
    this.loadOrders();
  }

  validateLogin() {
    this.documentNumber && this.storeCode ? true : this.router.navigate([`withdraws/option/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  loadOrders() {
    this.isLoading = true;

    const orders$ = this.orderApiService.getOrdersMobile(this.documentNumber, this.tenantId, this.storeCode);
    const stateAttention$ = this.orderApiService.getStateAttention(this.documentNumber, this.tenantId, this.storeCode);

    forkJoin([orders$, stateAttention$]).subscribe(
      results => {
        const listOrders = results[0];
        const listStateAttention = results[1];

        if (listOrders && listOrders.length > 0) {
          listOrders.map(order => {
            const stateAttention = listStateAttention.find(state => state.orderNumber === order.saleNoteNumber);
            if (stateAttention) {
              const status: string = stateAttention.status ?? 'none';
              const statusPicking: string = stateAttention.statusPicking ?? 'none';
              if (this.tenantId !== 'tpsa') {
                if (![ 'pending', 'progress' ].includes(statusPicking)) {
                  order.canRetire = true;
                  return;
                }
              }
              if (![ 'pending', 'progress'].includes(status)) {
                order.canRetire = true;
                return;
              }
            } else {
              order.canRetire = true;
            }
          });

          this.orders = listOrders;

          this.orders.forEach(order => {
            order.selected = true;
            order.products.forEach(product => product.selected = true);
          });

          this.selectedOrders = this.orders.filter(order => order.canRetire);
          this.disableAll = this.selectedOrders.every(order => !order.canRetire);
          this.isLoading = false;

          this.loadDataHeader();
        }
        else {
          this.isLoading = false;
          this.router.navigate([`withdraws/custom-error/${this.tenantId}/${this.storeCode}/${this.path}`]);
        }
      },
      error => {
        this.isLoading = false;
        this.router.navigate([`withdraws/error/${this.tenantId}/${this.storeCode}/${this.path}`]);
      }
    );
  }

  loadDataHeader(){
    if(this.orders && this.orders.length > 0){
      this.storeName = localStorage.getItem("storeName");
      this.customerFirstName = this.orders.map(order => order.customerName)[0]?.split(' ')[0];
      this.authorizedPersonDocumentNumber = this.orders.map(order => order.receptorDocumentNumber)[0];
      this.authorizedPersonFirstName = this.orders.map(order => order.receptor)[0]?.split(' ')[0];
    }
  }

  updateSelectedOrders(obj: any) {
    if (obj.selected) {
      const selectedOrder = this.orders.find(order => order.saleNoteNumber === obj.saleNoteNumber);
      this.selectedOrders.push(selectedOrder);
    } else {
      this.selectedOrders = this.selectedOrders.filter(order => order.saleNoteNumber !== obj.saleNoteNumber);
    }
  }

  pickupOrders() {
    this.isLoading = true;
    let mapperOrders: Order[] = this.mapperOrdersSelected(this.selectedOrders);
    console.log('pedidos seleccionados', mapperOrders);
    this.orderApiService.pushNotification(mapperOrders, this.tenantId, this.storeCode).subscribe(response => {
      let listOrderAttention: OrderAttention[] = [];
      mapperOrders.forEach((element) => {
        const newListProduct: any[] = [];
        element.listProduct.forEach(product => {
          if (product.checked === true) {
            newListProduct.push(product);
          }
        });
        let orderAttention = new OrderAttention(element.op, this.serviceWithDraw,
          element.documentNumber, element.customerName,
          element.orderId, element.dateDelivery, newListProduct, element.receptorDocumentNumber, element.receptor, element.sellCompany,
          element.customerEmail, element.customerPhone);
        listOrderAttention.push(orderAttention);
      });
      this.orderApiService.orderAttention(listOrderAttention, this.tenantId, this.storeCode, this.registerUser, 'ELO').subscribe((response: any) => {
        let first_iteration = true;

        response.data.forEach(order => {
          this.orderFirebaseService.saveNotification(this.tenantId, this.storeCode, {
            op: order.op,
            documentNumber: order.documentNumber,
            customerName: order.customerName
          });

          if (first_iteration) {
            this.orderFirebaseService.savePosVenta(this.tenantId, this.storeCode, {
              documentNumber: order.documentNumber,
              customerName: order.customerName
            });
          }

          this.orderFirebaseService.saveQueueManager(this.tenantId, this.storeCode, order.op, {
            op: order.op,
            documentNumber: order.documentNumber,
            customerName: order.customerName,
            dateDelivery: order.dateDelivery,
            receptorDocumentNumber: order.receptorDocumentNumber,
            receptorName: order.receptorName,
            statusPostSale: 'pending',
            statusPickingBoy: 'pending',
          });

          first_iteration = false;
        });

        this.isLoading = false;
        this.router.navigate([`withdraws/success/${this.tenantId}/${this.storeCode}/${this.path}`]);
      }, error => {
        console.log(error);
        this.isLoading = false;
        this.router.navigate([`withdraws/error/${this.tenantId}/${this.storeCode}/${this.path}`]);
      });
    }, error => {
      console.log(error);
      this.isLoading = true;
      this.router.navigate([`withdraws/error/${this.tenantId}/${this.storeCode}/${this.path}`]);
    });
  }

  mapperOrdersSelected(selectedOrders: OrderMobile[]): Order[] {
    const mapperOrders = [];
    selectedOrders.forEach(order => {
      const mapperProducts = [];
      order.products.forEach(product => {
        if (product.selected) {
          const mapperProduct = new ProductOrder(product.idProduct, product.quantity, null, product.productCode,
            product.productDescription, product.location, product.dispatchStatus, product.lpnNumber,
            product.dispatchNumber, product.selected, product.saleNoteNumber, product.orderId, product.measurable,
            product.unitMeasurement);
          mapperProducts.push(mapperProduct);
        }
      });

      const mapperOrder = new Order(null, order.saleNoteNumber, order.documentNumber, order.customerName,
        order.receptorDocumentNumber, order.receptor, order.dateDelivery, mapperProducts, order.selected, order.sellerCompany,
      order.customerEmail, order.customerPhone);
      mapperOrders.push(mapperOrder);
    });
    return mapperOrders;
  }

  backLogin() {
    this.router.navigate([`withdraws/login/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  updateOrdersVisibility(item: Product) {
    let saleNoteNumber = item.saleNoteNumber;
    let order = this.selectedOrders.find(order => order.saleNoteNumber === saleNoteNumber);
    if (!order) {
      order = this.orders.find(order => order.saleNoteNumber === saleNoteNumber);
      this.selectedOrders.push(order);
    }
    let product = order?.products?.find(product => product.idProduct === item.idProduct);
    product.selected = item.selected;

    this.selectedOrders = this.selectedOrders
      .filter(order => order.products.find(product => product.selected));
  }

}