import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Subscription } from 'rxjs';
import { OrderFirebaseService } from 'src/app/services/order-firebase.service';
import { OrderService } from 'src/app/services/order.service';
import { OrderRetired } from '../../models/models';
import { environment } from 'src/environments/environment';
import { ModalWithdrawalValidatedComponent } from '../modal-withdrawal-validated/modal-withdrawal-validated.component';

@Component({
  selector: 'order-retired-mobile',
  templateUrl: './order-retired-mobile.component.html',
  styleUrls: ['./order-retired-mobile.component.scss']
})
export class OrderRetiredMobileComponent implements OnInit, OnDestroy {

  showDetail = false;

  @Input() storeCode: string;
  @Input() documentNumber: string;
  @Input() companyCode: string;

  sectionBody: string = 'loading';
  orders: OrderRetired[] = [];
  withdrawnSalesNotes: string;

  timer: any;
  qrData: string = null;

  subscriptions: Subscription[] = [];
  saleNotes: string[] = [];

  @Output() onLoadError = new EventEmitter<Error>();
  @Output() onLoadReady = new EventEmitter<OrderRetired[]>();

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private orderService: OrderService, 
    private orderFirebaseService: OrderFirebaseService, 
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getOrdersRetired();
  }

  getOrdersRetired() {
    this.sectionBody = 'loading';

    const orderRetired$ = this.orderService.getOrdersRetired(this.documentNumber, this.companyCode, this.storeCode);
    const stateAttention$ = this.orderService.getStateAttention(this.documentNumber, this.companyCode, this.storeCode);

    forkJoin([orderRetired$, stateAttention$]).subscribe(
      results => {
        const listOrderRetired = results[0];
        const listOrderRetiredMapper = this.mapper(listOrderRetired);
        if (listOrderRetiredMapper && listOrderRetiredMapper.length > 0) {
          this.sectionBody = 'body';
          const data: OrderRetired[] = listOrderRetiredMapper;
          this.orders = data;
          const attentionsInProgress = results[1].filter(stateAttention => {
            if (this.companyCode === 'tpsa') {
              return [ 'pending', 'progress' ].includes(stateAttention.status);
            } else {
              return [ 'pending', 'progress' ].includes(stateAttention.statusPicking);
            }
          });

          listOrderRetiredMapper.forEach(attention => { this.refreshData(attention.saleNote); });
          if (attentionsInProgress && attentionsInProgress.length > 0) {
            attentionsInProgress.forEach(attention => { this.refreshData(attention.orderNumber); });
          }
        } else {
          this.sectionBody = 'sucessfull_empty';
        }
        this.onLoadReady.emit(listOrderRetired);
      }, error => {
        this.sectionBody = 'sucessfull_empty';
        this.onLoadError.emit(error);
      }
    );
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.subscriptions.forEach(suscription => {
      if (suscription) {
        suscription.unsubscribe();
      }
    });
  }

  mapper(data: OrderRetired[]): OrderRetired[] {
    let mapperData: OrderRetired[] = [];
    for (const order of data) {
      if (!order.withdrawalValidated) {
        const quantity = order.products.filter(product => product.deliveredQuantity !== null).length;
        if (quantity > 0) {
          const productsRetired = order.products.filter(product => product.deliveredQuantity !== null);
          order.products = productsRetired;
          mapperData.push(order);
        } else {
          console.log('picking boy no retiro el pedido: ', order.products);
        }
      }
    }
    return mapperData;
  }

  refreshData(orderNumber: string) {
    const orderRetired$ = this.orderService.getOrdersRetired(this.documentNumber, this.companyCode, this.storeCode);
    this.subscriptions.push(this.orderFirebaseService.getValuesQueueManager(this.companyCode, this.storeCode, orderNumber).subscribe((data: any[]) => {
      const statusFinished = Array.from([ 'finished', 'delivered' ]);
      const statusValidated = Array.from([ 'validated' ]);
      for (const item of data) {
        if (statusFinished.includes(item.statusPickingBoy) || statusFinished.includes(item.statusPostSale)) {
          if (!this.orders.map(attention => attention.saleNote).includes(item.op)) {            
            orderRetired$.subscribe(data => this.orders = this.mapper(data));
            break;
          }
        } else if (statusValidated.includes(item.statusPickingBoy) || statusValidated.includes(item.statusPostSale)) {          
          this.router.navigate([`/withdraws/mobile/order-list-validated/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
          this.dialog.open(ModalWithdrawalValidatedComponent, {
            panelClass: 'modal-panel-cancel',
            maxWidth: '90vw',
            data: this.orders.map(order => `#${order.saleNote}`).join(", "),
            disableClose: true
          });
        }
      }
    }));
  }

  canValidateWithdrawal(): boolean {
    return environment.commons.withdrawalValidationEnabled.includes(this.companyCode);
  }

  validateOrders() {
    this.qrData = `${this.companyCode}=${btoa(JSON.stringify({ attentionIds: this.orders.map(order => order.id) }))}`;
    this.withdrawnSalesNotes = this.orders.map(order => `#${order.saleNote}`).join(", ");
    this.sectionBody = 'validate_orders';
  }

}