<div fxLayout="row wrap" fxLayoutGap="8px" fxFlexFill>
    <div fxFlex="5" fxFlex.xs="5">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#F2536F"/>
        </svg>
      </div>
    </div>
    <div fxFlex="87" fxFlex.xs="87">
      <h1>{{dataMessage.title}}</h1>
      <p>{{dataMessage.message}}</p>
    </div>
</div>