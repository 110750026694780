import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderMobile, OrderRetired } from '../models/models';

@Component({
  selector: 'app-list-validated-orders',
  templateUrl: './list-validated-orders.component.html',
  styleUrls: ['./list-validated-orders.component.scss']
})
export class ListValidatedOrdersComponent implements OnInit {

  companyCode: string;
  storeCode: string;
  documentNumber: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.documentNumber = this.route.snapshot.paramMap.get('documentNumber');
  }

  getOrdersRetired($event: [OrderRetired[], OrderMobile[]]) {
    const orderRetiredList = $event[0];
    const orderPendingList = $event[1];
  }

  getOrdersError($event: Error) {
    console.error($event);
  }

  goMenu() {
    this.router.navigate([`/withdraws/mobile/menu/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
  }

}
