<div class="container">
	<div class="card">
		<div class="card__header">
			<span class="title">InStore IR</span>
			<img src="./assets/icon/icon__logo_32x32.svg"/>
		</div>
		<div class="card__content">
			<div class="logo__container">
				<img src="./assets/image/logo_instore.svg"/>
			</div>
			<div class="form">
				<span class="form__title">¡Bienvenido!</span>
				<form class="form__content" (ngSubmit)="selectSystem(systemForm)" #systemForm="ngForm">
					<mat-form-field appearance="fill" class="mb-16">
					  	<mat-label>Selecciona el módulo</mat-label>
					  	<mat-select [(ngModel)]="system" [required]="true" name="system" id="system">
							<mat-option *ngFor="let system of systems" [value]="system.value">
						  		{{ system.viewValue }}
							</mat-option>
					  	</mat-select>
					</mat-form-field>
					<button mat-raised-button type="submit" class="btn btn-primary">Ingresar</button>
				</form>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>