<ng-container *ngIf="sectionBody === 'loading'">
    <div class="container items-center justify-center h-full">
        <div class="loading">
            <div class="pb-12 loading__title">Cargando información</div>
            <mat-spinner diameter="118"></mat-spinner>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'body'">
    <div class="container p-2">
        <div class="container__header px-2 pt-2 pb-4">
            <div class="container__header__title mb-3">
                <img src="./assets/icon/menu__validated_orders.svg" />
                <h1 class="title m-0">Revisa tus pedidos validados</h1>
            </div>
            <p class="summary m-0">Visualiza los pedidos que haz retirado y validado al salir de la tienda.</p>
        </div>
        <div class="container__body overflow-y-auto">
            <div class="container__body__cards mb-2">
                <ng-container *ngFor="let order of orders">
                    <order-retired-card-mobile [order]="order" [showInfoAdditional]="'true'"></order-retired-card-mobile>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'sucessfull_empty'">
    <div class="container p-2 h-full">
        <div class="container__empty">
            <div class="title pt-2">No tienes pedidos validados</div>
        </div>
    </div>
</ng-container>