import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderRetired } from '../models/models';

@Component({
  selector: 'app-order-list-retired',
  templateUrl: './order-list-retired.component.html',
  styleUrls: ['./order-list-retired.component.scss']
})
export class OrderListRetiredComponent implements OnInit {

  companyCode: string;
  storeCode: string;
  documentNumber: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.documentNumber = this.route.snapshot.paramMap.get('documentNumber');
  }

  getOrdersRetired($event: OrderRetired[]) {
    console.log($event);
  }

  getOrdersError($event: Error) {
    console.error($event);
  }

  goMenu() {
    this.router.navigate([`/withdraws/mobile/menu/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
  }

}
