import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InstoreService } from 'src/app/services/instore.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

  @Input() companyCode: string;
  @Input() storeCode: string;
  @Input() idWithdrawalModality: number;

  @Output() onRedirectTo = new EventEmitter<boolean>();

  storeName:string;
  iconStorePath: string;
  withdrawalModality: any;

  constructor(private router: Router, private instoreService: InstoreService) { }

  ngOnInit(): void {
    const storeCode = localStorage.getItem("storeCodeMobile");
    const companyCode = localStorage.getItem("companyCodeMobile");

    if (this.companyCode === companyCode && this.storeCode === storeCode) {
      this.storeName = localStorage.getItem("storeNameMobile");
    } else {
      this.instoreService.getStores(this.companyCode).subscribe(
        data => {
          this.storeName = data.find(element => element.storeCode === this.storeCode).storeName
        }
      )
    }
    this.iconStorePath = `./assets/image/store_${this.companyCode}.svg`;
    this.withdrawalModality = environment.commons.withdrawalModalityConfig.find(config => config.id === this.idWithdrawalModality);
  }

  redirectTo() {
    this.onRedirectTo.emit(true);
  }

}