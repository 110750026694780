import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstoreService } from 'src/app/services/instore.service';
import { GenericHelper } from 'src/app/util/generic-helpers';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit {
  redirect: boolean = false;

  stores: any[];
  companyCode: string;
  storeCode: string;
  storeName: string;
  iconStorePath: string;
  documentNumber: string;

  constructor(
    private instoreService: InstoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngAfterViewInit(): void {
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.iconStorePath = `./assets/image/store_${this.companyCode}.svg`;
    this.redirect = GenericHelper.checkWithdrawsRedirect(this.companyCode, this.storeCode);
    if(this.redirect){
      GenericHelper.withdrawsRedirect(this.companyCode, this.storeCode);
    }    
  }

  ngOnInit(): void {
    const storeCode = localStorage.getItem("storeCodeMobile");
    const companyCode = localStorage.getItem("companyCodeMobile")
    if (this.companyCode === companyCode && this.storeCode === storeCode) {
      this.storeName = localStorage.getItem("storeNameMobile");
    } else {
      this.instoreService.getStores(this.companyCode).subscribe(
        data => {
          this.stores = data;
          localStorage.setItem("companyCodeMobile", this.companyCode);
          localStorage.setItem("storeCodeMobile", this.storeCode);
          this.storeName = this.stores.find(element => element.storeCode === this.storeCode).storeName
          localStorage.setItem("storeNameMobile", this.storeName);
        }
      )
    }  
  }

  ingresar() {
    localStorage.setItem("documentNumberMobile", this.documentNumber);
    this.router.navigate([`withdraws/mobile/menu/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
  }

}