import { AfterViewInit, ApplicationRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'new-version',
  templateUrl: './new-version.component.html',
  styleUrls: ['./new-version.component.scss']
})
export class NewVersionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output('pendingUpdate') pendingUpdate$ = new EventEmitter<boolean>();
  @Output('versionApp') versionApp$ = new EventEmitter<string>();
  versionEnd: string;
  versionApp: string;
  updatePending = false;
  subscription: Subscription;

  @Input() padding = '0px'

  constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef) { }


  ngOnInit(): void {
    this.versionEnd = environment.commons.versionApp;
    this.updatePending = JSON.parse(localStorage.getItem('updatePending'));
    this.versionApp = localStorage.getItem('versionApp');
  }

  ngAfterViewInit(): void {
    this.loadUpdateWorker();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadUpdateWorker() {
    let isApplicationStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    let isReadyForVersionUpgrade$ = concat(isApplicationStable$, interval(1 * 60 * 1000));

    this.subscription = isReadyForVersionUpgrade$.subscribe(() => {
      console.log("checking for version upgrade...")
      this.swUpdate.checkForUpdate();
    });
    

    this.swUpdate.available.subscribe(i => {
      console.log('A new version of the application available new', i.current, i.available);
      Swal.fire({
        html: 'La web de Instore tiene una nueva versión. ¿Deseas iniciar la actualización?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#6d837a',
        cancelButtonColor: '#f5705c',
        confirmButtonText: 'Sí',
        cancelButtonText: 'Mas tarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          this.swUpdate.activateUpdate().then(async () => {
            console.log("activateUpdate");
            await this.clearCache();
            await this.unregister();
            document.location.reload();
          });
        } else {
          console.log("pending update");
          this.updatePending = true;
          this.pendingUpdate$.next(this.updatePending);
          localStorage.setItem('updatePending', JSON.stringify(this.updatePending));
          this.versionApp = environment.commons.versionApp;
          localStorage.setItem('versionApp', this.versionApp);
          this.versionApp$.next(this.versionApp);
        }
      });
    })
  }

  updateApp() {
    console.log('updateApp');
    this.updatePending = false;
    this.pendingUpdate$.next(this.updatePending);
    localStorage.setItem('updatePending', JSON.stringify(this.updatePending));
    document.location.reload();
  }

  async unregister() {
		let registration = await navigator.serviceWorker.ready;
		await registration.unregister();
	}

  async clearCache() {
    const cacheNames = await self.caches.keys();
		cacheNames.forEach((cacheName) => {
			caches.delete(cacheName);
		});
	}
}
