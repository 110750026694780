import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  companyCode: string;
  storeCode: string;
  buttonState = true;
  documentNumberVal = '';
  documentType = '';
  document: any;
  path: string;

  ngOnInit(): void  {
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.path = this.route.snapshot.paramMap.get('path');
    this.documentType = 'DNI';
  }

  login(documentNumber: any) {
    localStorage.setItem('documentNumber', documentNumber);
    localStorage.setItem('documentType', this.documentType);
    this.router.navigate([`/withdraws/register-customer/${this.companyCode}/${this.storeCode}/${this.path}`]);
  }

  validateLength(documentNumber: string) {
    const input = <HTMLInputElement>(document.getElementById('document_number'));
    this.documentNumberVal = this.limitCharacters(14, documentNumber).toUpperCase();
    this.buttonState = (/^[a-zA-Z0-9]{7,14}$/.test(this.documentNumberVal)) ? false : true;
  }

  resetInput() {
    this.documentNumberVal = '';
    this.buttonState = true;
  }

  limitCharacters(limitLength, value) {
    let limit = value.toString();
    if (limit.length > limitLength) limit = limit.substring(0, limitLength)
    return limit;
  }

  enterNumber(event) {
    if (event.target.getAttribute('data-attribute') === null || event.target.getAttribute('data-attribute') === 'null') {
      this.documentNumberVal = this.documentNumberVal.slice(0, -1);
    } else {
      this.documentNumberVal += event.target.getAttribute('data-attribute');
    }
    this.validateLength(this.documentNumberVal);
  }

  saveDocumentType(event:any) {
    this.documentType = event.target.value;
  }

  keyPressOnlyNumber(event) {​​​​​
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {​​​​​
        return true;
    }​​​​​ else {​​​​​
    event.preventDefault();return false;
    }
​​  ​​​}​​​​​

  back(){
      this.router.navigate([`/withdraws/option/${this.companyCode}/${this.storeCode}/${this.path}`]);
  }


}
