<ng-container  *ngIf="versionApp === versionEnd">
    <ng-container  *ngIf="updatePending">
        <div style="width: 100%;" [style.padding]="padding">
            <div (click)="updateApp()" class="updated__app">
                <span class="material-icons updated__app__icon">
                    autorenew
                </span>
                <span>
                    Hay una actualización pendiente. Haz click aquí para iniciarla.
                </span>
            </div>
        </div>
    </ng-container>
</ng-container>