import { Component, OnInit } from '@angular/core';
import { ScreenService } from 'src/app/util/screen.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  versionApp: string;
  currentYear: number;

  constructor(private screen: ScreenService) { }

  ngOnInit(): void {
    this.versionApp = environment.commons.versionApp;
    this.currentYear = new Date().getFullYear();
  }

  isScreenSmall(): boolean {
    return this.screen.sizes['screen-x-small'];
  }

}
