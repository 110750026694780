import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  tenantId: string;
  
  constructor( private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
  }

}