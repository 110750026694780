<div class="errorContainer">
    <div class="go_back" (click)="backLogin()">
        <mat-icon class="icon color-{{tenantId}}">keyboard_arrow_left</mat-icon>
        <span class="message color-{{tenantId}}">Atrás</span>
    </div>
    <div class="error_body">
        <span class="title color-{{tenantId}}">Estamos presentando inconvenientes en este momento</span>
        <p class="message mb-0 mt-10">Por favor, acércate a nuestro módulo de atención para poder ayudarte.</p>
        <div class="buttons_group {{tenantId}} mt-17">
            <button mat-raised-button class="btn-primary-v3" (click)="finished()">
                ACEPTAR
                <mat-icon>check</mat-icon>
            </button>
            <button mat-raised-button class="btn-outline-primary-v3 {{tenantId}}" (click)="backLogin()">
                <mat-icon>keyboard_arrow_left</mat-icon>
                VOLVER A INTENTAR
            </button>
        </div>
    </div>
    <img class="img_network" src="./assets/image/img__network.svg"/>
</div>