<ng-container *ngIf="sectionBody === 'loading'">
    <div class="container items-center justify-center h-full">
        <div class="loading">
            <div class="pb-12 loading__title">Cargando información </div>
            <mat-spinner diameter="118"></mat-spinner>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'body'">
    <div class="container p-2">
        <div class="container__header px-2 pt-2 pb-4">
            <div class="container__header__title">
                <div class="title_wrapper">
                    <div class="title__group">
                        <img src="./assets/icon/icon__select_products.svg" />
                        <h1 class="title m-0">Selecciona el pedido a retirar</h1>
                    </div>
                    <mat-checkbox class="pr-2"
                        [checked]="allSelected"
                        [indeterminate]="someComplete"
                        (change)="setAll($event.checked)"
                        [disabled]="disabledAll">
                    </mat-checkbox>
                </div>
                <p class="summary pt-3 m-0">Para retirar tus pedidos, ten en cuenta el tamaño y el peso del producto.</p>
            </div>
        </div>
        <div class="container__body overflow-y-auto">
            <div class="container__body__cards mb-2">
                <ng-container *ngFor="let order of orders">
                    <app-order-card-mobile [order]="order"></app-order-card-mobile>
                </ng-container>
            </div>
            <button mat-raised-button *ngIf="disabledAll" class="btn btn-primary mt-4 mb-2 mx-2" [disabled]="true">
                <span *ngIf="attentionInProgress.length > 0">Atención en curso</span>
                <span *ngIf="attentionInProgress.length === 0">Pago en proceso</span>
                <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.833 3.667 12.5 7H15c0 2.758-2.242 5-5 5a4.892 4.892 0 0 1-2.334-.583L6.45 12.633A6.609 6.609 0 0 0 10 13.668 6.665 6.665 0 0 0 16.666 7h2.5l-3.333-3.333zM5 7c0-2.758 2.241-5 5-5 .841 0 1.641.208 2.333.583l1.217-1.216A6.61 6.61 0 0 0 10 .333 6.665 6.665 0 0 0 3.333 7h-2.5l3.333 3.334L7.5 7H5z" fill="currentColor"/>
                </svg>
            </button>
            <button mat-raised-button *ngIf="!disabledAll" class="btn btn-primary mt-4 mb-2 mx-2" (click)="pickupOrders()" [disabled]="retirarDisable">
                <span>Retirar pedidos seleccionados</span>
                <svg width="17" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.858 11a1.934 1.934 0 0 0-1.931 1.931c0 1.066.866 1.932 1.931 1.932a1.934 1.934 0 0 0 1.932-1.932A1.934 1.934 0 0 0 12.858 11zm0 2.634a.703.703 0 1 1 .002-1.407.703.703 0 0 1-.002 1.407zM3.936 1.422A.615.615 0 0 0 3.338.95H1.475a.614.614 0 1 0 0 1.23h1.378l1.825 7.638.049-.011-.049.011a.615.615 0 0 0 .598.472h8.789a.614.614 0 0 0 .596-.467l1.374-5.551a.615.615 0 0 0-.597-.763H4.434l-.498-2.087zm10.717 3.316-1.07 4.323H5.762L4.728 4.738h9.925zM6.022 11a1.934 1.934 0 0 0-1.931 1.931c0 1.066.866 1.932 1.931 1.932a1.934 1.934 0 0 0 1.932-1.932A1.934 1.934 0 0 0 6.023 11zm0 2.634a.703.703 0 1 1 .002-1.407.703.703 0 0 1-.002 1.407z" fill="currentColor" stroke="#fff" stroke-width=".1"/>
                </svg>                
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'sucessfull'">
    <div class="container h-full p-2">
        <div class="register">
            <div class="loading__title pt-1">¡Hemos registrado tu atención! En unos minutos te entregaremos tu
                pedido.</div>
            <img src="./assets/image/receptionist.svg" width="142" height="175" class="py-1">
            <button mat-raised-button class="container__register__button mx-2 mb-1" (click)="finalize()">
                <span>Finalizar &nbsp;&nbsp;<img src="./assets/image/finalize.svg" width="16" height="16"></span>
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'sucessfull_empty'">
    <div class="container h-full p-2">
        <div class="container__empty">
            <div class="title pt-2">No tienes pedidos pendientes de retiro</div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'recordWithdrawal'">
    <div class="container h-full p-2">
        <div class="register">
            <div class="loading__title pt-1">
                ¡Hemos registrado tu atención! En unos minutos te entregaremos tu pedido
            </div>
            <img src="./assets/image/recordWithdrawal02.gif" height="228px" width="160px" class="my-1">
            <div class="loading__title pt-1">
                Procura no cerrar esta pantalla ya que emitiremos el detalle de retiro
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'error'">
    <div class="container h-full p-2">
        <div class="register">
            <div class="loading__title pt-1"><b>En este momento presentamos inconvenientes </b><br><br>
                Por favor, acércate a nuestro módulo de Retiro en Tienda</div>
            <img src="./assets/image/receptionist.svg" width="142" height="175" class="my-1">
            <button mat-raised-button class="container__register__button mx-2 mb-1" (click)="finalize()">
                <span>Finalizar &nbsp;&nbsp;<img src="./assets/image/finalize.svg" width="16" height="16"></span>
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'error01'">
    <div class="container h-full p-2">
        <div class="register">
            <div class="loading__title pt-1"><b>En este momento presentamos inconvenientes </b><br><br>
                Por favor, acércate a nuestro módulo de Retiro en Tienda en la zona de Electro</div>
            <img src="./assets/image/receptionist.svg" width="142" height="175" class="my-1">
            <button mat-raised-button class="container__register__button mx-2 mb-1" (click)="finalize()">
                <span>Finalizar &nbsp;&nbsp;<img src="./assets/image/finalize.svg" width="16" height="16"></span>
            </button>
        </div>
    </div>
</ng-container>
