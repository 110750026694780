import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderFirebaseService {

  order: AngularFireList<any>;
  dbPath: string = environment.urlDBFirebase;

  orderPostSale: AngularFireList<any>;
  dbPathPostSale: string = environment.urlDBFirebasePostSale;
  dbPathQueueManager: string = environment.urlDBFirebaseQueueManager;
  orderQueueManager: AngularFireList<any>;

  constructor(private db: AngularFireDatabase) { }

  // Section notification
  saveNotification(idCompany: string, idStore: string, body: any) {
    this.order = this.db.list(`${this.dbPath}/${idCompany}/${idStore}`);
    this.order.push(body);
  }

  getAllNotifications(idCompany: string, idStore: string): Observable<any> {
    return this.db.list(`${this.dbPath}/${idCompany}/${idStore}`)
      .snapshotChanges((['child_added']))
      .pipe(map(changes => changes.map(c => ({ key: c.payload.key, value: c.payload.val() }))));
  }

  deleteAllNotifications(idCompany: string, idStore: string) {
    this.orderPostSale = this.db.list(`${this.dbPath}/${idCompany}/${idStore}`);
    this.orderPostSale.remove();
  }

  delete(idCompany: string, idStore: string, key: any) {
    this.order = this.db.list(`${this.dbPath}/${idCompany}/${idStore}`);
    this.order.remove(key);
  }

  // Post Venta
  savePosVenta(idCompany: string, idStore: string, body: any) {
    this.orderPostSale = this.db.list(`${this.dbPathPostSale}/${idCompany}/${idStore}`);
    this.orderPostSale.push(body);
  }

  getValuesPostSale(idCompany: string, idStore: string): Observable<any> {
    return this.db.list(`${this.dbPathPostSale}/${idCompany}/${idStore}`)
      .snapshotChanges((['child_added']))
      .pipe(map(response => response.map(data => this.assignKey(data))));
  }

  deleteAllPostSale(idCompany: string, idStore: string) {
    this.orderPostSale = this.db.list(`${this.dbPathPostSale}/${idCompany}/${idStore}`);
    this.orderPostSale.remove();
  }

  // Queue Manager

  saveQueueManager(idCompany: string, idStore: string, op: string, body: any) {
    this.orderQueueManager = this.db.list(`${this.dbPathQueueManager}/${idCompany}/${idStore}/${op}`);
    this.orderQueueManager.push(body);
  }

  getQueueManager(tenantId: string, storeId: string, saleNoteId: string) {
    return this.db.list(`${this.dbPathQueueManager}/${tenantId}/${storeId}/${saleNoteId}`)
      .snapshotChanges((['child_added', 'child_changed']))
      .pipe(map(changes => changes.map(c => ({ key: c.payload.key, value: c.payload.val() }))));
  }

  getNewQueueManager(tenantId: string, storeId: string) {
    return this.db.list(`${this.dbPathQueueManager}/${tenantId}/${storeId}`)
      .snapshotChanges((['child_added']))
      .pipe(map(changes => changes.map(c => ({ key: c.payload.key, value: c.payload.val() }))));
  }

  getUpdateQueueManager(tenantId: string, storeId: string) {
    return this.db.list(`${this.dbPathQueueManager}/${tenantId}/${storeId}`)
      .snapshotChanges((['child_changed']))
      .pipe(map(changes => changes.map(c => ({ key: c.payload.key, value: c.payload.val() }))));
  }

  getValuesQueueManager(tenantId: string, storeId: string, op: string): Observable<any> {
    return this.db.list(`${this.dbPathQueueManager}/${tenantId}/${storeId}/${op}`).snapshotChanges((['child_changed']))
      .pipe(map(response => response.map(data => this.assignKey(data))));
  }

  updateQueueManager(tenantId: string, storeId: string, saleNoteId: string, key: string, value: any): Promise<void> {
    this.orderQueueManager = this.db.list(`${this.dbPathQueueManager}/${tenantId}/${storeId}/${saleNoteId}`);
    return this.orderQueueManager.update(key, value);
  }

  private assignKey(data: any) {
    return { ...data.payload.val(), key: data.key }
  }

}