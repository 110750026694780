import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstoreService {

  constructor( private http: HttpClient) { }

  apiInstore = environment.ApiInstore;
  
  getStores(companyCode: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;'
      })
    };
    return this.http.get<any[]>(`${this.apiInstore}/store/${companyCode}`, httpOptions);   
   }

}
