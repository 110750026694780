import { Component, Input, OnInit } from '@angular/core';
import { ScreenService } from 'src/app/util/screen.service';
import { OrderRetired } from '../../models/models';

@Component({
  selector: 'order-retired-card-mobile',
  templateUrl: './order-retired-card-mobile.component.html',
  styleUrls: ['./order-retired-card-mobile.component.scss']
})
export class OrderRetiredCardMobileComponent implements OnInit {

  @Input() order: OrderRetired;
  @Input() showInfoAdditional: boolean = false;

  panelOpenState = false;

  constructor(private screen: ScreenService) { }

  ngOnInit(): void {
  }

  isScreenSmall(): boolean {
    return this.screen.sizes['screen-x-small'];
  }

}