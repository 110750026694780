import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [],
  },
  { 
    path: '',
    loadChildren: () => import('./authentication/authentication.module').then(x => x.AuthenticationModule),
    canActivate: [],
  },
  { 
    path: 'withdraws',
    loadChildren: () => import('./withdraws/withdraws.module').then(x => x.WithdrawsModule),
    canActivate: [],
  },
  { 
    path: 'post-sales',
    loadChildren: () => import('./post-sales/post-sales.module').then(x => x.PostSalesModule),
    canActivate: [],
  },
  { 
    path: 'queue-manager',
    loadChildren: () => import('./queue-manager/queue-manager.module').then(x => x.QueueManagerModule),
    canActivate: [],
  },
  { 
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(x => x.NotificationsModule),
    canActivate: [],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
