import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    _system: any;
    subject = new Subject();

    constructor() {
    }

    markFormGroupTouched(form: FormGroup) {
        Object.values(form.controls).forEach(control => {
           control.markAsTouched();

            if ((control as any).controls) {
               this.markFormGroupTouched(control as FormGroup);
            }
        });
    }

    setSystem(system: any) {
        this._system = system;
        this.subject.next(this._system);
    }

    clearSytem(){
        this._system = '';
        localStorage.setItem('nameSystem', '');
        this.subject.next(this._system);
    }
}