import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/util/util.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  systems: any[] = environment.commons.systems;
  system: any;
  versionApp: string;

  constructor(public utilService: UtilService, private router: Router) { }

  ngOnInit(): void {
    this.versionApp = environment.commons.versionApp;
  }

  selectSystem(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }
    console.log(this.system);
    if (this.system == 'withdraws') {
      this.router.navigate(['/withdraws']);
    } else if (this.system == 'post-sales') {
      this.router.navigate(['/post-sales']);
    } else if (this.system == 'queue-manager') {
      localStorage.setItem("companyCode", "tpsa");
      this.router.navigate(['/queue-manager']);
    } else if (this.system == 'notifications') {
      this.router.navigate(['/notifications']);
    }
  }

}
