import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatesNotificationComponent } from './app-updates-notification/updates-notification.component';
import { NewVersionComponent } from './new-version/new-version.component';
import { VersionComponent } from './version/version.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppMessageComponent } from './app-message/message.component';

@NgModule({
  declarations: [
    UpdatesNotificationComponent,
    NewVersionComponent,
    VersionComponent,
    AppMessageComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    UpdatesNotificationComponent,
    NewVersionComponent,
    VersionComponent,
    AppMessageComponent
  ]
})
export class SharedModule { }
