<div class="loginContainer">
    <div class="go_back" (click)="back()">
        <mat-icon class="icon {{tenantId}}">keyboard_arrow_left</mat-icon>
        <span class="message {{tenantId}}">Atrás</span>
    </div>
    <div class="login_body">
        <div class="loginSection_info">
            <span class="title {{tenantId}}">Bienvenido</span>
            <span class="message">Digita el Nº de Documento de Identidad<br/>o RUC del titular de la compra:</span>
        </div>
        <div class="loginSection_calculator">
            <div class="calculator_container {{tenantId}}">
                <div class="input_container {{tenantId}}">
                    <input class="input_document_number" name="document-number" autocomplete="off" max="12" (keypress)="keyPressOnlyNumber($event);" #document_number id="document_number" [(ngModel)]="documentNumberVal" (change)="validateLength(document_number.value)" (keyup)="validateLength(document_number.value)">
                    <p class="message_validation m-0" *ngIf="documentNumberVal.length > 0 && documentNumberVal.length <= 7">Ingresa un documento de identidad válido.</p>
                    <p class="message_validation m-0" *ngIf="documentNumberVal.length == 10">Ingresa un documento de identidad válido.</p>
                    <p class="message_validation m-0" *ngIf="documentNumberVal.length >= 12">Has ingresado el máximo de caracteres permitidos.</p>
                </div>
                <div class="numbers_container">
                    <div class="buttons_group {{tenantId}}">
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="1">1</button>
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="2">2</button>
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="3">3</button>
                    </div>
                    <div class="buttons_group {{tenantId}}">
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="4">4</button>
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="5">5</button>
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="6">6</button>
                    </div>
                    <div class="buttons_group {{tenantId}}">
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="7">7</button>
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="8">8</button>
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="9">9</button>
                    </div>
                    <div class="buttons_group {{tenantId}}">
                        <button type="button" class="btn-number" (click)="enterNumber($event)" data-attribute="0">0</button>
                        <button type="button" class="btn-icon-eraser" (click)="enterNumber($event)" data-attribute="null">
                            <mat-icon>backspace</mat-icon>
                        </button>
                    </div>
                </div>
                <button mat-raised-button class="btn-primary-v3" (click)="login(documentNumberVal)" [disabled]="buttonState">
                    INGRESAR
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>