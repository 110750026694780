import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Base64 } from 'js-base64';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  urlCustomer = environment.ApiCustomer;
  basicAuthenticationUsernameHPSA = environment.BasicAuthenticationUsernameHPSA;
  basicAuthenticationPasswordHPSA = environment.BasicAuthenticationPasswordHPSA;
  basicAuthenticationUsernameTPSA = environment.BasicAuthenticationUsernameTPSA;
  basicAuthenticationPasswordTPSA = environment.BasicAuthenticationPasswordTPSA;
  basicAuthenticationUsernameSPSA = environment.BasicAuthenticationUsernameSPSA;
  basicAuthenticationPasswordSPSA = environment.BasicAuthenticationPasswordSPSA;
  basicAuthenticationUsernameRP = environment.BasicAuthenticationUsernameRP;
  basicAuthenticationPasswordRP = environment.BasicAuthenticationPasswordRP;

  getCustomer(document: string, documentType: string, companyCode: string) {
    let authentication = '';
    if(companyCode === 'hpsa'){
        authentication = Base64.encode(this.basicAuthenticationUsernameHPSA+":"+this.basicAuthenticationPasswordHPSA)
    }else if(companyCode === 'tpsa'){
        authentication = Base64.encode(this.basicAuthenticationUsernameTPSA+":"+this.basicAuthenticationPasswordTPSA)
    }else if(companyCode === 'spsa'){
        authentication = Base64.encode(this.basicAuthenticationUsernameSPSA+":"+this.basicAuthenticationPasswordSPSA)
    }else if(companyCode === 'rp'){
        authentication = Base64.encode(this.basicAuthenticationUsernameRP+":"+this.basicAuthenticationPasswordRP)
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic '+authentication
      })
    };

    return this.http.get(`${this.urlCustomer}${document}&document_type=${documentType}`, httpOptions);
  }
}
