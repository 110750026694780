import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { OrderFirebaseService } from 'src/app/services/order-firebase.service';
import { OrderService } from 'src/app/services/order.service';
import { Order, OrderAttention, Product as ProductOrder } from 'src/app/withdraws/models/models';
import { OrderMobile, Product } from '../../models/models';

@Component({
  selector: 'order-pending-mobile',
  templateUrl: './order-pending-mobile.component.html',
  styleUrls: ['./order-pending-mobile.component.scss']
})
export class OrderPendingMobileComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() storeCode: string;
  @Input() documentNumber: string;
  @Input() companyCode: string;
  @Input() idWithdrawalModality: number;

  orders: OrderMobile[] = []
  allSelected: boolean = false;
  disabledAll: boolean = false;
  disabledAllByAttention: boolean = false;
  disabledAllByNoPaid: boolean = false;
  selectedOrders: Order[] = [];
  sectionBody: string = 'loading';

  timer: any;

  subscriptions: Subscription[] = [];
  saleNotes: string[] = [];

  @Output() onLoadError = new EventEmitter<Error>();
  @Output() onLoadReady = new EventEmitter<OrderMobile[]>();
  @Output() onQueueManager = new EventEmitter<boolean>();
  @Output() onLoader = new EventEmitter<boolean>();
  customerName: string;

  attentionInProgress: any[] = [];
  withdrawalsPaymentInProgress: any[] = [];

  constructor(private orderService: OrderService,
    private orderFirebaseService: OrderFirebaseService,
    private router: Router,
    private orderApiService: OrderService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getOrders();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  getOrders() {
    this.sectionBody = 'loading';
    this.onLoader.emit(true);
    const orderPending$ = this.orderService.getOrdersMobile(this.documentNumber, this.companyCode, this.storeCode);
    const stateAttention$ = this.orderService.getStateAttention(this.documentNumber, this.companyCode, this.storeCode);

    forkJoin([orderPending$, stateAttention$]).subscribe(
      results => {
        this.onLoader.emit(false);
        const listOrderPending = results[0];
        const listStateAttention = results[1];

        if (listOrderPending && listOrderPending.length > 0) {
          listOrderPending.map(order => {
            const stateAttention = listStateAttention.find(state => state.orderNumber === order.saleNoteNumber);
            if (stateAttention) {
              const status: string = stateAttention.status ?? 'none';
              const statusPicking: string = stateAttention.statusPicking ?? 'none';
              if (this.companyCode !== 'tpsa') {
                if (![ 'pending', 'progress' ].includes(statusPicking)) {
                  order.canRetire = true;
                  return;
                }
              }
              if (![ 'pending', 'progress'].includes(status)) {
                order.canRetire = true;
                return;
              }
            } else {
              order.canRetire = true;
            }
          });

          this.saleNotes = listOrderPending.filter(orderPending => !orderPending.canRetire).map(orderPending => orderPending.saleNoteNumber);
          if (this.saleNotes.length > 0) {
            this.refreshDataAll(this.saleNotes);
          }

          this.sectionBody = 'body';
          this.orders = listOrderPending;
          let products: Product[] = [];
          this.orders.forEach(order => {
            products = products.concat(order.products);
          });
          const flag = products.filter(t => t.selected).length > 0;

          this.attentionInProgress = listOrderPending.filter(order => !order.canRetire);
          this.withdrawalsPaymentInProgress = listOrderPending.filter(order => order.canRetire && !order.paymentDate);
          this.disabledAll = (this.attentionInProgress.length + this.withdrawalsPaymentInProgress.length) === listOrderPending.length;

          this.allSelected = flag;
          this.customerName = this.orders[0].customerName;
        } else {
          this.sectionBody = 'sucessfull_empty';
        }

        this.onLoadReady.emit(listOrderPending);
      },
      error => {
        this.onLoader.emit(false);
        this.error(error);
      }
    )
  }

  pickupOrders() {
    this.onLoader.emit(true);
    this.sectionBody = 'loading';
    this.selectedOrders = this.mapperOrdersSelected(this.orders);
    this.orderApiService.pushNotification(this.selectedOrders, this.companyCode, this.storeCode).subscribe(response => {
      let listOrderAttention: OrderAttention[] = [];
      this.selectedOrders.forEach((element) => {
        const newListProduct: any[] = [];
        element.listProduct.forEach(product => {
          if (product.checked === true) {
            newListProduct.push(product);
          }
        });
        let orderAttention = new OrderAttention(element.op, this.idWithdrawalModality, element.documentNumber,
          this.capitalizeWords(element.customerName), element.orderId, element.dateDelivery, newListProduct,
          element.receptorDocumentNumber, element.receptor, element.sellCompany, element.customerEmail,
          element.customerPhone);
        listOrderAttention.push(orderAttention);
      });
      this.orderApiService.orderAttention(listOrderAttention, this.companyCode, this.storeCode, 'CUSTOMER', 'QR').subscribe((response: any) => {
        let first_iteration = true;
        response.data.forEach(order => {
          this.orderFirebaseService.saveNotification(this.companyCode, this.storeCode, {
            op: order.op,
            documentNumber: order.documentNumber,
            customerName: order.customerName,
            customerEmail: order.customerEmail,
            customerPhone: order.customerPhone,
            idWithdrawalModality: this.idWithdrawalModality
          });

          if (first_iteration) {
            this.orderFirebaseService.savePosVenta(this.companyCode, this.storeCode, {
              documentNumber: order.documentNumber,
              customerName: order.customerName,
              customerEmail: order.customerEmail,
              customerPhone: order.customerPhone
            });
          }

          this.onLoader.emit(false);
          this.orderFirebaseService.saveQueueManager(this.companyCode, this.storeCode, order.op, {
            op: order.op,
            documentNumber: order.documentNumber,
            customerName: order.customerName,
            customerEmail: order.customerEmail,
            customerPhone: order.customerPhone,
            dateDelivery: order.dateDelivery,
            receptorDocumentNumber: order.receptorDocumentNumber,
            receptorName: order.receptorName,
            statusPostSale: 'pending',
            statusPickingBoy: 'pending',
          });

          this.sectionBody = 'recordWithdrawal';
          this.onQueueManager.emit(true);

          if (!this.saleNotes.includes(order.op)) {
            this.refreshData(order.op);
          }
        });
      }, error => {
        this.onLoader.emit(false);
        this.error(error)
      });
    }, error => {
      this.onLoader.emit(false);
      this.error(error)
    });
  }

  mapperOrdersSelected(orders: OrderMobile[]): Order[] {
    const selectedOrders = [];
    orders.forEach(order => {
      if (order.canRetire && order.paymentDate !== null && order.paymentDate !== undefined) {
        const existProduct = order.products.find(product => product.selected)
        if (existProduct) {
          const productsSelected = order.products.filter(product => product.selected);
          if (productsSelected && productsSelected.length > 0) {
            const mapperProducts: ProductOrder[] = [];

            productsSelected.forEach(product => {
              const mapperProduct = new ProductOrder(product.idProduct, product.quantity, null, product.productCode,
                product.productDescription, product.location, product.dispatchStatus,
                product.lpnNumber, product.dispatchNumber, product.selected, product.saleNoteNumber, product.orderId, product.measurable, product.unitMeasurement);
              mapperProducts.push(mapperProduct);
            })

            const mapperOrder = new Order(null, order.saleNoteNumber, order.documentNumber,
              order.customerName, order.receptorDocumentNumber,
              order.receptor, order.dateDelivery, mapperProducts, order.selected, order.sellerCompany, order.customerEmail,
              order.customerPhone)
            selectedOrders.push(mapperOrder);
          }
        }
      }
    });

    console.log('mapperOrdersSelected', selectedOrders);
    return selectedOrders;
  }

  refreshDataAll(saleNotes: string[]) {
    saleNotes.forEach(saleNote => this.refreshData(saleNote));
  }

  refreshData(saleNote: string) {
    this.subscriptions.push(this.orderFirebaseService.getValuesQueueManager(this.companyCode, this.storeCode, saleNote).subscribe((data: any[]) => {
      const statusFinished = Array.from(['finished', 'delivered']);
      for (const item of data) {
        if (statusFinished.includes(item.statusPickingBoy) || statusFinished.includes(item.statusPostSale)) {
          this.router.navigate([`withdraws/mobile/order-list-retired/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
          break;
        }
      }
    }));
  }

  finalize() {
    this.router.navigate([`withdraws/mobile/${this.companyCode}/${this.storeCode}`]);
  }

  redirect() {
    this.timer = setTimeout(() => {
      this.router.navigate([`withdraws/mobile/${this.companyCode}/${this.storeCode}`]);
    }, 10000);
  }

  capitalizeWords(string) {
    string = string.toLowerCase();
    return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
  };

  error(error: Error) {
    //48: higuereta  / 68: primavera
    if (this.companyCode == 'spsa' && (this.storeCode == '48' || this.storeCode == '68')) {
      this.sectionBody = 'error01';
    } else {
      this.sectionBody = 'error';
    }

    this.onLoadError.emit(error)
    this.redirect()
  }

  get retirarDisable() {
    if (this.allSelected) {
      return false;
    }

    return !this.someComplete;
  }

  get someComplete(): boolean {
    const orders = this.orders.filter(order => order.canRetire && order.paymentDate !== null && order.paymentDate !== undefined);
    if (orders == null) {
      return false;
    }
    let products: Product[] = [];
    orders.forEach(order => products = products.concat(order.products));
    let cantSelect: number = products.filter(t => t.selected).length;
    if (cantSelect === products.length) {
      this.allSelected = true;
      return null;
    } else if (cantSelect > 0 && cantSelect < products.length) {
      return true;
    } else if (cantSelect === 0) {
      this.allSelected = false;
      return null;
    }
    return null;
  }

  setAll(selected: boolean) {
    this.allSelected = selected;
    if (this.orders == null) {
      return;
    }
    this.orders.forEach(order => {
      if (order.canRetire && order.paymentDate !== null && order.paymentDate !== undefined) {
        order.selected = selected;
        order.products.forEach(product => {
          product.selected = selected;
        })
      }
    });
    console.log(this.orders);
  }

}
