<div class="customErrorContainer">
    <div class="go_back" (click)="backLogin()">
        <mat-icon class="icon color-{{tenantId}}">keyboard_arrow_left</mat-icon>
        <span class="message color-{{tenantId}}">Atrás</span>
    </div>
    <div class="error_body">
        <span class="title color-{{tenantId}}">No encontramos pedidos a retirar</span>
        <p class="message mb-0 mt-10">Lo sentimos, no encontramos pedidos pendientes de recojo con el documento de identidad ingresado.</p>
        <div class="buttons_group {{tenantId}} mt-21">
            <button mat-raised-button class="btn-primary-v3" (click)="finished()">
                FINALIZAR
                <mat-icon>check</mat-icon>
            </button>
            <button mat-raised-button class="btn-outline-primary-v3 {{tenantId}}" (click)="backLogin()">
                <mat-icon>keyboard_arrow_left</mat-icon>
                INGRESAR OTRO DOCUMENTO
            </button>
        </div>
    </div>
    <img class="img_network" src="./assets/image/img__without-withdraw.svg"/>
</div>