<app-loading [hidden]="!isLoading">
</app-loading>
<div fxLayout="column" fxLayoutAlign="center center" class="container">
    <div *ngIf="companyCode === 'hpsa' || companyCode === 'spsa'" class="title">Hemos registrado tu atención, pronto<br>
                       te llamaremos
    </div>
    <div *ngIf="companyCode === 'tpsa'" class="title">Por favor, espera a que te llamemos</div>
    <div *ngIf="companyCode === 'rp'" class="title">Hemos registrado tu atención, pronto</div>
    <div *ngIf="companyCode === 'rp'" class="subtitle-rp">te llamaremos</div>
    <div *ngIf="companyCode === 'tpsa'" class="subtitle">Verifica tu número de atención en la pantalla</div>
    
    <img [ngStyle.xs]="{'width.px': 100, 'height.px': 100}" [ngStyle.sm]="{'width.px': 150, 'height.px': 150}" class="image" src="../../assets/image/register-{{companyCode}}.png">
    <button mat-raised-button color="primary" class="button-finish-{{companyCode}}" (click)="finished()">FINALIZAR</button>
</div>
