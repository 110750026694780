<app-loading *ngIf="isLoading"></app-loading>
<div class="ordersContainer" *ngIf="!isLoading">
    <div class="go_back" (click)="backLogin()">
        <mat-icon class="icon {{tenantId}}">keyboard_arrow_left</mat-icon>
        <span class="message {{tenantId}}">Atrás</span>
    </div>
    <div class="titleWithdrawal_group">
        <span class="title">¡Hola {{ customerFirstName | titlecase }}!</span>
        <span class="subtitle mt-2">Selecciona el pedido y los productos a retirar</span>
    </div>
    <div class="withdrawalData_group mt-10">
        <div class="data_group">
            <mat-icon class="icon {{tenantId}}">store</mat-icon>
            <span class="message {{tenantId}}">Tienda:</span>
            <span class="message_value">{{ storeName }}</span>
        </div>
        <div class="data_group">
            <mat-icon class="icon {{tenantId}}">person</mat-icon>
            <span class="message {{tenantId}}">Persona Autorizada:</span>
            <span class="message_value">{{ authorizedPersonFirstName | titlecase }}</span>
        </div>
        <div class="data_group">
            <mat-icon class="icon {{tenantId}}">assignment_ind</mat-icon>
            <span class="message {{tenantId}}">Doc. de identidad:</span>
            <span class="message_value">{{ authorizedPersonDocumentNumber }}</span>
        </div>
    </div>
    <div class="orders_group mt-10"> 
        <ng-container *ngFor="let order of orders">
            <app-order-card [order]="order" class="order-container"
                (updateSelectedOrders)="updateSelectedOrders($event)" 
                (updateOrders)="updateOrdersVisibility($event)">
            </app-order-card>
        </ng-container>
    </div>
    <div class="buttons_group mt-10 {{tenantId}}">
        <button mat-raised-button color="primary" class="btn_pick-up" (click)="pickupOrders()" [disabled]="selectedOrders.length === 0" *ngIf="!disableAll">RETIRAR</button>
        <button mat-raised-button color="primary" class="btn_pick-up" (click)="pickupOrders()" [disabled]="true" *ngIf="disableAll">ATENCIÓN EN CURSO</button>
    </div>
</div>