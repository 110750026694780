<div class="container">
    <header-mobile 
        [companyCode]="companyCode" 
        [storeCode]="storeCode" 
        [idWithdrawalModality]="idWithdrawalModality"
        (onRedirectTo)="goMenu();"></header-mobile>
    <order-pending-mobile 
        [companyCode]="companyCode"
        [storeCode]="storeCode" 
        [documentNumber]="documentNumber"
        [idWithdrawalModality]="idWithdrawalModality"
        (onLoadReady)="getOrdersPending($event)"
        (onLoadError)="getOrdersError($event)"></order-pending-mobile>
</div>