<div fxLayout="row" >
    <div fxFlex="15%" fxFlex.xs="30%" fxFlex.sm="30%" fxLayoutAlign="end center" class="back-{{companyCode}}">
        <img class="icon-back" src="../../../../assets/image/back-{{companyCode}}.png" (click)="back()"> <div (click)="back()">Atrás</div>
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="start" class="container">
    <div fxFlexOffset="10%" fxFlex="50%" fxFlex.xs="80%" fxFlex.sm="80%" fxFlex.md="80%" fxLayout="column">
        <div fxFlex="73%" fxLayoutAlign="start start" class="title">
            Ingresa tu N° de documento de identidad: 
        </div>
        <div fxFlex="20%" fxLayoutAlign="end start">
            <select class="document-types" (change)="saveDocumentType($event)" name="documentTypes">
                <option value="DNI" >DNI</option>
                <option value="FOREIGN_DOCUMENT" >C. Extranjería</option>
                <option value="PASSPORT" >Pasaporte</option>
                <option value="RUC" >RUC</option>
            </select>
            <input name="document-number" autocomplete="off" (keypress)="keyPressOnlyNumber($event);" class="document" #document_number id="document_number" [(ngModel)]="documentNumberVal" (change)="validateLength(document_number.value)" (keyup)="validateLength(document_number.value)">
        </div>
    </div>
    <div fxFlexOffset="5%" fxFlex="30%" fxLayout="column" fxHide.xs fxHide.sm fxHide.md >
        <div fxLayout="row">
            
           <div fxLayout="column" fxFlex="30%">
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="1"  >1</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="4"  >4</button> 
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="7"  >7</button>
            </div>
            <div fxLayout="column" fxFlex="30%">
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="2" >2</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="5" >5</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="8" >8</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="0" >0</button>
            </div>
            <div fxLayout="column" fxFlex="30%">
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="3" >3</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="6" >6</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="9" >9</button>
                <button type="button" class="button-calculator" (click)="enterNumber($event)" data-attribute="null" ><img src="../../../../assets/image/delete.png" alt="delete"></button>
            </div>
        </div>
    </div> 
</div>
<div fxLayout="row" fxLayoutAlign="center end">
    <button mat-raised-button color="primary" class="button-ok-{{companyCode}}" [disabled]="buttonState" (click)="login(documentNumberVal)">INGRESAR</button>
</div>

