<mat-expansion-panel class="panel__order" (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="false" togglePosition="before">
    <mat-expansion-panel-header collapsedHeight="45px" expandedHeight="45px" style="padding-left: 0.75rem; padding-right: 0px; background-color: #e5eae5 !important;">
        <mat-panel-title>
            <span class="header__pedido">Pedido # {{order.saleNoteNumber}}</span>
            <span style="flex: 1 1 0%; box-sizing: border-box;"></span>
            <mat-checkbox [checked]="order.selected"
                [indeterminate]="someComplete()" 
                (change)="setAll($event.checked)"
                (click)="$event.stopPropagation()" 
                [disabled]="!order.canRetire || !order.paymentDate"
                (mouseenter)="!order.canRetire ? tooltip1.show() : !order.paymentDate ? tooltip2.show() : undefined" 
                (mouseleave)="!order.canRetire ? tooltip1.hide() : !order.paymentDate ? tooltip2.hide() : undefined">
                <span #tooltip1="matTooltip"
                    matTooltip="Este pedido ya está en proceso de retiro"
                    matTooltipPosition="below"
                    matTooltipClass="my-custom-tooltip"></span>
                <span #tooltip2="matTooltip"
                    matTooltip="Este pedido está en proceso de pago"
                    matTooltipPosition="below"
                    matTooltipClass="my-custom-tooltip"></span>
            </mat-checkbox>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column">
        <div fxLayout="column" class="px-2 mb-4">
            <div class="card__group pb-4" style="display: flex; flex-direction: column;">
                <div style="display: flex; flex-direction: row; align-items: center;" class="pb-1">
                    <img src="./assets/icon/icon_authorized_person.svg" width="16" height="16" class="pedido__item__icon">
                    <span class="pedido__item__label">Persona Autorizada / Doc. Identidad:</span>
                </div>
                <span class="pedido__item__value pl-6">{{ order.receptor | titlecase }} / {{ order.receptorDocumentNumber }}</span>
            </div>
            <div class="card__group pb-4" style="display: flex; flex-direction: row; align-items: center;">
                <img src="./assets/icon/icon_calendar.svg" width="16" height="16" class="pedido__item__icon">
                <span class="pedido__item__label">Fecha de Recojo:&nbsp;</span>
                <span class="pedido__item__value">{{ order.dateDelivery | date: 'dd/MM/yyyy' }}</span>
            </div>
        </div>
        <div>
            <table style="width: 100%; font-size: 14px; color: #707070; font-weight: 500;">
                <tr style="background-color: none; border-bottom: 0.5px solid rgba(171, 176, 186, 0.5);">
                    <th style="font-weight: normal; color: #323232;">Cant.</th>
                    <th style="font-weight: normal; color: #323232;">Producto</th>
                    <th></th>
                </tr>
                <tr *ngFor="let product of order.products" class="tr__body">
                    <td style="padding: 0.5rem; text-align: left; text-transform: lowercase;">{{product.quantity}}</td>
                    <td style="padding: 0.5rem; text-align: left; text-transform: lowercase;">{{product.productDescription}}</td>
                    <td style="padding: 0.5rem; text-align: right;">
                        <mat-checkbox [(ngModel)]="product.selected" (ngModelChange)="updateAllSelected()" [disabled]="!order.canRetire || !order.paymentDate">
                        </mat-checkbox>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</mat-expansion-panel>