import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent implements OnInit {

  tenantId: string;
  storeCode: string;
  path : string;
  timer: any;

  constructor(private router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.path = this.route.snapshot.paramMap.get('path');
    if(this.path == "0") {
     this.timer = setTimeout(() => {
        this.router.navigate([`withdraws/login/${this.tenantId}/${this.storeCode}/${this.path}`]);
      }, 10000);
    } else if(this.path == "1") {
      this.timer = setTimeout(() => {
        this.router.navigate([`withdraws/option/${this.tenantId}/${this.storeCode}/${this.path}`]);
      }, 10000);
    }
  }

  finished(){ 
    clearTimeout(this.timer);
    this.router.navigate([`/withdraws/welcome/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  backLogin() {
    clearTimeout(this.timer);
    this.router.navigate([`withdraws/login/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }
}