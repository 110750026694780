import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderMobile } from '../models/models';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  companyCode: string;
  storeCode: string;
  iconStorePath: string;
  documentNumber: string;
  idWithdrawalModality: number;

  customerName: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.documentNumber = this.route.snapshot.paramMap.get('documentNumber');
    this.idWithdrawalModality = Number(this.route.snapshot.paramMap.get('withdrawalModality') || 4);
  }

  getOrdersPending($event: OrderMobile[]) {
    const orderPendingList = $event;
    if (orderPendingList && orderPendingList.length > 0) {
      this.customerName = orderPendingList[0].customerName;
    }
  }

  getOrdersError($event: Error) {
    console.error($event);
  }

  showOrdersRetired() {

    this.router.navigate([`withdraws/mobile/order-list-retired/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
  }

  goMenu() {
    this.router.navigate([`/withdraws/mobile/menu/${this.companyCode}/${this.storeCode}/${this.documentNumber}`]);
  }

}
