import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QueueManagerRoutingModule } from './queue-manager-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { PendingOrderListComponent } from './pending-order-list/pending-order-list.component';
import { WithdrawsModule } from '../withdraws/withdraws.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    PendingOrderListComponent
  ],
  imports: [
    CommonModule,
    QueueManagerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    FlexLayoutModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
    WithdrawsModule,
    CarouselModule,
    QRCodeModule
  ]
})
export class QueueManagerModule { }
