import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-register-customer',
  templateUrl: './register-customer.component.html',
  styleUrls: ['./register-customer.component.scss']
})
export class RegisterCustomerComponent implements OnInit {
  companyCode: string;
  storeCode: string;
  isLoading: boolean = true;
  documentType: string='';
  document: string='';
  customerName: string='';
  identityDocument: string='';
  userUse: any;
  path: string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private customerApiService: CustomerService,
    private orderApiService: OrderService) { }
  
  ngOnInit(): void {
    this.isLoading = true;
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.document =localStorage.getItem("documentNumber");
    this.documentType = localStorage.getItem("documentType");
    this.userUse = localStorage.getItem("userUse");
    this.path = this.route.snapshot.paramMap.get('path');
    console.log(this.document);
    let response;
    let error;
    this.customerApiService.getCustomer(this.document, this.documentType, this.companyCode).subscribe(
      res =>{
        response = res;
        console.log(response.data);
        if(response.data != null && response.data != undefined){
          this.identityDocument = response.data.identityDocument;
          if (this.identityDocument == null || this.identityDocument == undefined || this.identityDocument == ''){
            this.identityDocument = this.document;
          }
          if(response.data.name != undefined && response.data.name != null && response.data.lastName != undefined && response.data.lastName != null){
            this.customerName = this.capitalize(response.data.name + " " + response.data.lastName);
          } else if(response.data.name != undefined && response.data.name != null){
            this.customerName = this.capitalize(response.data.name);
          } else if(response.data.lastName != undefined && response.data.lastName != null){
            this.customerName = this.capitalize(response.data.lastName);
          }
        } else{
            this.customerName = this.document;
            this.identityDocument = this.document;
        }
        this.isLoading = false;
      },
      err =>{
        error = err;
        console.log(error);
        this.router.navigate([`withdraws/error/${this.companyCode}/${this.storeCode}/${this.path}`]);
      }
    )
  }

  finished(){
    let option = localStorage.getItem("option");
    const jsonOrderAttention = [{
      customerName: this.customerName,
      documentNumber: this.identityDocument,
      op: null,
      service: option
    }];
    console.log(jsonOrderAttention);
    this.orderApiService.orderAttention(jsonOrderAttention, this.companyCode, this.storeCode, this.userUse, 'ELO').subscribe();
    this.router.navigate([`withdraws/option/${this.companyCode}/${this.storeCode}/${this.path}`]);
  }

 capitalize(s){
    return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
  };
}
