<ng-container *ngIf="sectionBody === 'loading'">
    <div class="container items-center justify-center h-full">
        <div class="loading">
            <div class="pb-12 loading__title">Cargando información</div>
            <mat-spinner diameter="118"></mat-spinner>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'body'">
    <div class="container p-2">
        <div class="container__header px-2 pt-2 pb-4">
            <div class="container__header__title mb-3">
                <ng-container *ngIf="canValidateWithdrawal()">
                    <img src="./assets/icon/menu__withdrawn_orders.svg" />
                    <h1 class="title m-0">Valida la entrega de tu pedido</h1>
                </ng-container>
                <ng-container *ngIf="!canValidateWithdrawal()">
                    <img src="./assets/icon/menu__validated_orders.svg" />
                    <h1 class="title m-0">Revisa tus pedidos retirados</h1>
                </ng-container>
            </div>
            <p *ngIf="canValidateWithdrawal()" class="summary m-0">Para salir de la tienda debes validar tus pedidos retirados con el personal de seguridad</p>
            <p *ngIf="!canValidateWithdrawal()" class="summary m-0">Visualiza los pedidos que haz retirado y validalo al salir de la tienda.</p>
        </div>
        <div class="container__body overflow-y-auto">
            <div class="container__body__cards mb-2">
                <ng-container *ngFor="let order of orders">
                    <order-retired-card-mobile [order]="order"></order-retired-card-mobile>
                </ng-container>
            </div>
            <button mat-button class="button__validate mt-4 mb-2" (click)="validateOrders()" *ngIf="canValidateWithdrawal()">
                Generar QR
                <img src="./assets/icon/icon__qr_code.svg">
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'validate_orders'">
    <div class="container p-2 h-full">
        <div class="container__header px-2 pt-2">
            <div class="container__header__title mb-3">
                <img src="./assets/icon/menu__withdrawn_orders.svg" />
                <h1 class="title m-0">Valida la entrega de tu pedido</h1>
            </div>
            <p class="message m-0">{{ withdrawnSalesNotes }}</p>
        </div>
        <div class="container__body__qr">
            <div class="qr__container">
                <qrcode #parent 
                    [qrdata]="qrData" 
                    [width]="194" 
                    [errorCorrectionLevel]="'M'" 
                    [margin]="1" 
                    [elementType]="'img'"
                    style="text-align: center;"></qrcode>
            </div>
            <p class="message m-0">
                Muestra este código QR al personal de seguridad en la puerta de salida
            </p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="sectionBody === 'sucessfull_empty'">
    <div class="container p-2 h-full">
        <div class="container__empty">
            <div class="title pt-2">No tienes pedidos retirados</div>
        </div>
    </div>
</ng-container>