<div class="successContainer">
    <div class="go_back" (click)="backLogin()">
        <mat-icon class="icon color-{{tenantId}}">keyboard_arrow_left</mat-icon>
        <span class="message color-{{tenantId}}">Atrás</span>
    </div>
    <div class="success_body">
        <span class="title color-{{tenantId}}">¡Ya estamos buscando tu pedido!</span>
        <p *ngIf="tenantId === 'tpsa'" class="message mb-0 mt-10">Verifica tu número de atención en la pantalla.</p>
        <p *ngIf="tenantId !== 'tpsa'" class="message mb-0 mt-10">Permanece en la zona de retiro y te llamaremos cuando esté listo.</p>
        <div class="buttons_group {{tenantId}} mt-24">
            <button mat-raised-button class="btn-primary-v3" (click)="finished()">
                FINALIZAR
                <mat-icon>check</mat-icon>
            </button>
            <button mat-raised-button class="btn-outline-primary-v3 {{tenantId}}" (click)="backLogin()">
                <mat-icon>keyboard_arrow_left</mat-icon>
                SEGUIR RETIRANDO
            </button>
        </div>
    </div>
    <img class="img_success" src="./assets/image/img__search.svg"/>
</div>