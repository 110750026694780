import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  qrData = environment.WebPlatform;
  webPlatform = environment.WebPlatform;

  tenantId: string;
  storeCode: string;
  path : string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  
  ngOnInit(): void  {
    this.path = localStorage.getItem("path"); 
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.qrData = `${this.webPlatform}/withdraws/mobile/${this.tenantId}/${this.storeCode}`;
  }

  enter(){
    if(this.path === '0'){
      this.router.navigate([`/withdraws/login/${this.tenantId}/${this.storeCode}/0`]);
    }
    else if(this.path === '1'){
      this.router.navigate([`/withdraws/option/${this.tenantId}/${this.storeCode}/1`]);
    }
  }
}
