import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Base64 } from 'js-base64';
import { OrderMobile, OrderRetired, StateAttention } from '../withdraws/mobile/models/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  apiOrders = environment.ApiOrders;
  apiOrderAttentionMobile = environment.ApiOrderAttentionMobile
  apiPushNotification = environment.ApiPushNotification;
  apiOrderAttention = environment.ApiOrderAttention;
  userBasicAuthenticationOrderAttention = environment.userBasicAuthenticationOrderAttention;
  keyBasicAuthenticationOrderAttention = environment.keyBasicAuthenticationOrderAttention;


  getOrdersMobile(documentNumber: string, companyCode: string, storeCode: string): Observable<OrderMobile[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;'
      })
    };
    return this.http.get<OrderMobile[]>(`${this.apiOrderAttentionMobile}/${companyCode}/orders?storeCode=${storeCode}&documentNumber=${documentNumber}`, httpOptions).pipe(map(orders => this.mapperOrderMobile(orders)));
  }

  mapperOrderMobile(orders: OrderMobile[]): OrderMobile[] {
    const indeterminate = false;
    const selected = true;
    orders.forEach(order => {
      order.selected = selected;
      order.indeterminate = indeterminate;
        order.products.forEach(product => {
          product.selected = selected;
        })
    })

    return orders;
  }

  getOrders(documentNumber: any, companyCode: any, storeCode: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;'
      })
    };

    return this.http.get(`${this.apiOrders}/api/customer-care/v1/delivery/${companyCode}/${documentNumber}/${storeCode}`, httpOptions);
  }

  pushNotification(orders: any, companyCode: any, storeCode: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body = {
      data: orders,
      storeCode: storeCode,
      companyCode: companyCode
    };

    return this.http.post(`${this.apiPushNotification}`, body, httpOptions);
  }

  orderAttention(listOrderAttention: any, companyCode: any, storeCode: any, userUse: any, fromWeb:'QR' | 'ELO' ) {
    console.log(listOrderAttention, 'storeOrders');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Basic ' + Base64.encode(this.userBasicAuthenticationOrderAttention + ":" + this.keyBasicAuthenticationOrderAttention)
      })
    };

    const body = {
      data: listOrderAttention,
      storeCode: storeCode,
      companyCode: companyCode,
      userUse: userUse,
      fromWeb:fromWeb
    };

    return this.http.post(`${this.apiOrderAttention}/order-attention`, body, httpOptions);
  }

  getOrdersRetired(documentNumber: string, companyCode: string, storeCode: string): Observable<OrderRetired[]> {
    let params = new HttpParams();
    params = params.set('tenantId', companyCode);
    params = params.set('storeCode', storeCode);
    params = params.set('services', 'Retiro en tienda, Retiro al auto');
    params = params.set('status', 'finished,delivered');
    params = params.set('documentNumber', documentNumber);

    if (companyCode !== 'tpsa') {
      params = params.set('picking', true);
    } else {
      params = params.set('picking', false);
    }
    
    return this.http.get<OrderRetired[]>(`${this.apiOrderAttention}/attentions`, { params });
  }

  getStateAttention(documentNumber: string, companyCode: string, storeCode: string): Observable<StateAttention[]> {
    return this.http.get<StateAttention[]>(`${this.apiOrderAttention}/order-attention/state-attention/${documentNumber}?storeCode=${storeCode}&companyCode=${companyCode}`)
    .pipe(
      map(result => {
       const stateAttentions: StateAttention[] =  result['data'] ;
       return stateAttentions;
      })
    );
  }

  validateWithdrawal(body: any) {
    return this.http.post<any[]>(`${this.apiOrderAttention}/withdrawal/validate`, body);
  }

}