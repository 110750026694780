<div class="optionContainer">
    <div class="go_back" (click)="backWelcome()">
        <mat-icon class="icon {{tenantId}}">keyboard_arrow_left</mat-icon>
        <span class="message {{tenantId}}">Atrás</span>
    </div>
    <span class="title">¿En qué te podemos ayudar?</span>
    <div class="option_body mt-16">
        <div class="option">
            <img class="w-100" src="./assets/image/pick_up_in_store_{{tenantId}}.svg" (click)="withdraw()">
        </div>
        <div class="option">
            <img class="w-100" src="./assets/image/technical_service_{{tenantId}}.svg" (click)="serviceTecnic()">
        </div>
        <div class="option">
            <img class="w-100" src="./assets/image/change_and_return_{{tenantId}}.svg" (click)="changeReturn()">
        </div>
        <div class="option">
            <img class="w-100" src="./assets/image/other_requests_{{tenantId}}.svg" (click)="othersRequest()">
        </div>
    </div>
</div>