<div class="container" *ngIf="!redirect; else loadTemplate">
    <div class="container__header">
        <img [src]="iconStorePath" alt="" width="54" height="45" class="mt-4">
        <div class="container__header__title mt-4">
            Módulo de Retiro en Tienda
        </div>
        <div class="container__header__subtitle mt-4 mb-4">
            {{storeName}}
        </div>
    </div>
    <div class="container__body py-3">
        <div class="container__body__title px-6 pt-12">Bienvenido</div>
        <div class="container__body__subtitle px-6 pb-12 pt-12">Ingresa tu
            documento de identidad
            para visualizar los pedidos a retirar:</div>
        <input type="text" inputmode="numeric" 
              placeholder="Ingresa tu documento de identidad" 
              [ngModel]="documentNumber"
              (ngModelChange)="documentNumber = $event.toUpperCase()"
              ird-validate="alphanumeric" maxlength="14"
              class="container__body__input mx-2 mb-16">
        <button mat-raised-button 
                class="container__body__button mt-auto mb-12 mx-2" 
                [disabled]="!(documentNumber && documentNumber.length > 6)"
                (click)="ingresar()">
            <span>Ingresar  </span>
        </button>
    </div>
    <footer-mobile></footer-mobile>
</div>
<ng-template #loadTemplate>
    <div style="padding: 10px;">Cargando plataforma...</div>
</ng-template>