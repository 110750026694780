<mat-expansion-panel class="panel__order__retired" (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="false" togglePosition="before">
    <mat-expansion-panel-header collapsedHeight="45px" expandedHeight="45px" style="padding-left: 0.75rem; padding-right: 0px; background-color: #e5eae5 !important;">
        <mat-panel-title>
            <span class="header__pedido">Pedido # {{ order.saleNote }}</span>
            <span style="flex: 1 1 0%; box-sizing: border-box;"></span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column">
        <div fxLayout="column" class="mb-2">
            <div class="card__group flex-col pb-4">
                <div class="flex flex-row items-center pb-1">
                    <img src="./assets/icon/icon_authorized_person.svg" width="16" height="16" class="pedido__item__icon">
                    <span class="pedido__item__label">Persona Autorizada / Doc. Identidad:</span>
                </div>
                <span class="pedido__item__value pl-6">{{ order.receptorName | titlecase }} / {{ order.receptorDocumentNumber }}</span>
            </div>
            <div class="card__group flex-row items-center pb-4">
                <img src="./assets/icon/icon_calendar.svg" width="16" height="16" class="pedido__item__icon">
                <span class="pedido__item__label">Fecha de Recojo:&nbsp;</span>
                <span class="pedido__item__value">{{ order.endTime | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
            </div>
            <div class="card__group flex-col pb-4">
                <div class="flex flex-row items-center pb-1">
                    <img src="./assets/icon/icon_person.svg" width="16" height="16" class="pedido__item__icon">
                    <span class="pedido__item__label">Titular de la compra / Doc. Identidad:</span>
                </div>
                <span class="pedido__item__value pl-6">{{ order.customerName | titlecase }} / {{ order.customerDocumentNumber }}</span>
            </div>
            <div *ngIf="showInfoAdditional" class="card__group flex-col pb-4">
                <div class="flex flex-row items-center">
                    <img src="./assets/icon/icon_withdrawal_validated.svg" width="16" height="16" class="pedido__item__icon">
                    <span class="pedido__item__label">Retiro validado:</span>
                    <span class="pedido__item__value ml-1">{{ order.dateValidation | date: 'dd/MM/yyyy HH:mm:ss' }}</span> 
                </div>
            </div>
        </div>
        <div>
            <table style="width: 100%; font-size: 14px; color: #707070; font-weight: 500;">
                <tr style="background-color: none; border-bottom: 0.5px solid rgba(171, 176, 186, 0.5);">
                    <th style="font-weight: normal; color: #323232; text-align: left;">Cant.</th>
                    <th style="font-weight: normal; color: #323232; text-align: left; padding: 0 0.5rem;">Producto</th>
                    <th style="font-weight: normal; color: #323232; text-align: right; padding-right: 0.5rem;">SKU</th>
                </tr>
                <tr *ngFor="let product of order.products" class="tr__body">
                    <td style="padding: 0.5rem; text-align: left; text-transform: lowercase;">{{ product.deliveredQuantity }}</td>
                    <td style="padding: 0.5rem; text-align: left; text-transform: lowercase;">{{ product.description }}</td>
                    <td style="padding: 0.5rem; text-align: right; text-transform: lowercase;">{{ product.skuCode }}</td>   
                </tr>
            </table>
        </div>
    </div>
</mat-expansion-panel>
