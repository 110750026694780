<div class="orderExpansionPanel">
    <div class="panel_header" (click)="toggleShowDetail()">
        <div class="header_group {{tenantId}}">
            <mat-checkbox class="check_order" [(ngModel)]="order.selected" 
                [disabled]="!order.canRetire"
                (change)="onChange($event)" 
                (click)="$event.stopPropagation()"
                (mouseenter)="!order.canRetire ? tooltip.show() : undefined" 
                (mouseleave)="!order.canRetire ? tooltip.hide() : undefined">
                <span #tooltip="matTooltip"
                    matTooltip="Este pedido ya está en proceso de retiro"
                    matTooltipPosition="below"
                    matTooltipClass="my-custom-tooltip">
                </span>
            </mat-checkbox>

            <div class="data_container">
                <div class="data_group">
                    <span class="message font-bold flex-150">N° de pedido:</span>
                    <span class="message">{{ order.saleNoteNumber }}</span>
                </div>
                <div class="data_group">
                    <span class="message font-bold flex-150">Fecha de recojo:</span>
                    <span class="message">{{ order.dateDelivery | date: 'dd-MM-yyyy' }}</span>
                </div>
                <div class="data_group">
                    <span class="message font-bold flex-150">N° de productos:</span>
                    <span class="message">{{ numProducts }}</span>
                </div>
            </div>
            
            <mat-icon class="icon_show_detail {{tenantId}}" [hidden]="showDetail">
                keyboard_arrow_down
            </mat-icon>
            <mat-icon class="icon_show_detail {{tenantId}}" [hidden]="!showDetail">
                keyboard_arrow_up
            </mat-icon>
        </div>
    </div>
    <div class="panel_body" [hidden]="!showDetail">
        <div class="productsTable_container">
            <mat-table [dataSource]="productsDataSource" class="{{tenantId}}">
                <ng-container matColumnDef="checked">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="{{tenantId}}">
                            <mat-checkbox [checked]="element?.selected" (change)="onProductChange($event, element)" [disabled]="!order.canRetire"></mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef>CANTIDAD</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element?.quantity }}</mat-cell>
                </ng-container>
            
                <ng-container matColumnDef="productDescription">
                    <mat-header-cell *matHeaderCellDef>PRODUCTO</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element?.productDescription }}</mat-cell>
                </ng-container>
            
                <mat-header-row *matHeaderRowDef="displayedOrderColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedOrderColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
</div>