<div class="container">
    <span class="text-instore text-center">InStore InDigitalXP {{currentYear}} - Customer Care</span>
    <span class="text-version text-center">Versión {{versionApp}}</span>
    <div class="logo_container">
        <div class="logo_group group_1">
            <img src="./assets/image/logo_footer-promart.svg" />
            <img src="./assets/image/logo_footer-oeschsle.svg" />
            <img src="./assets/image/logo_footer-plazavea.svg" />
        </div>
        <div class="logo_group group_2">
            <img src="./assets/image/logo_footer-realplaza.svg" />
        </div>
    </div>
</div>
