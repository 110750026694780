import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {  ActivatedRoute } from '@angular/router';
import { OrderMobile, Product } from 'src/app/withdraws/mobile/models/models';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {

  @Input() order: OrderMobile;
  @Input() disabled: boolean;
  @Output() updateSelectedOrders: EventEmitter<any> = new EventEmitter();
  @Output() updateOrders: EventEmitter<any> = new EventEmitter();

  tenantId: string;
  storeName: string;
  numProducts: number = 0;
  
  allCheckBox: boolean = true;
  indeterminate: boolean = false;
  showDetail: boolean = false;

  displayedOrderColumns = [ 'checked', 'quantity', 'productDescription' ];
  productsDataSource = new MatTableDataSource([]);

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.order.products.forEach(product => this.numProducts += Number(product.quantity));
    this.storeName = localStorage.getItem("storeName");
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
    this.productsDataSource = new MatTableDataSource(this.order.products);
  }

  onChange($event) {
    this.order.selected = $event.checked;
    this.allCheckBox = $event.checked;
    this.indeterminate = null;
    this.order.products.forEach(product => product.selected = $event.checked);
    this.updateSelectedOrders.emit(this.order);
  }

  onProductChange($event, product: Product) {
    product.selected = $event.checked;
    this.validate();
    this.updateOrders.emit(product);
  }

  onProductAllChange($event) {
    this.order.selected = $event.checked;
    this.order.products.forEach(product => product.selected = $event.checked);
    this.updateSelectedOrders.emit(this.order);
  }

  validate() {
    let selectedProducts = this.order.products.filter(t => t.selected).length;
    if (selectedProducts > 0 && selectedProducts < this.order.products.length) {
      this.allCheckBox = false;
      this.order.selected = true;
      this.indeterminate = true;
    } else if (selectedProducts === this.order.products.length) {
      this.allCheckBox = true;
      this.order.selected = true;
      this.indeterminate = false;
    } else {
      this.allCheckBox = false;
      this.order.selected = false;
      this.indeterminate = false;
    }
  }

  toggleShowDetail(){
    this.showDetail = !this.showDetail;
  }
}