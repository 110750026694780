<!-- <div fxLayout="row" class="top-container" fxLayoutAlign="none end">
	<div fxFlexOffset="2%" class="title">InStore IR</div>
</div> -->


<div class="peding_order_list_empty" *ngIf="orders.length == 0">
	<div class="peding_order_list_empty__container">
		<div></div>
		<div class="content">
			<div class="content__message">
				<div class="content__message__container">
					<h1 class="content__message__title">¡Hola!</h1>
					<h2 class="content__message__subtitle">Para iniciar tu retiro escanea desde tu celular este código QR 
						<span class="content__message__arrow"> 
							<svg width="140" height="30" viewBox="0 0 140 30" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2 13C0.895431 13 9.65645e-08 13.8954 0 15C-9.65645e-08 16.1046 0.89543 17 2 17L2 13ZM139.414 16.4142C140.195 15.6332 140.195 14.3668 139.414 13.5858L126.686 0.857875C125.905 0.0768264 124.639 0.0768263 123.858 0.857875C123.077 1.63892 123.077 2.90525 123.858 3.6863L135.172 15L123.858 26.3137C123.077 27.0948 123.077 28.3611 123.858 29.1421C124.639 29.9232 125.905 29.9232 126.686 29.1421L139.414 16.4142ZM2 17L138 17L138 13L2 13L2 17Z" fill="white"/>
							</svg>
						</span> 
					</h2>
				</div>
			</div>
			<div class="content__qr">
				<div class="qr__image">
					<qrcode #parent
						[qrdata]="qrData"
						[width]="392"
						[errorCorrectionLevel]="'M'"
						[margin]="1"
						[elementType]="'img'"></qrcode> 
				</div>
			</div>
		</div>
		<div class="footer">
			<img src="../../../../assets/image/logo_oechsle_footer.png" alt="logo oechsle">
		</div>
	</div>
</div>

<div #pendingOrderList class="pending_order_list" *ngIf="orders.length > 0">
	<div class="pending_order_list__head">
		<div class="pending_order_list__head__container">
			<div class="to_attetion">
				<div class="to_attetion__title">
					<span>Turno de</span> 
				</div>
				<div class="to_attetion__user">
					<span class="to_attetion__user__name to_attetion__user__name--progress" *ngIf="orders[0].status == 'progress'" >{{ orders[0].customerName }}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="pending_order_list__container">
		<div class="content" *ngIf="orders.length <= 5">
			<div class="content__user_list" >
				<div class="item" [class.item--disabled]="order.status == 'blank'" *ngFor="let order of orders; index as i" >
					<div class="item__box">
						<span>{{i + 1}}</span>
					</div>
					<div class="item__user">
						<div class="item__user__name">
							<span>{{order.customerName}}</span>
						</div>
						<div class="item__user__status" [class.item__user__status--waiting]="order.status == 'pending'"></div>
					</div>
				</div>
			</div>
			<div class="content__banner">
				<div class="content__banner__container">
					<div class="message">
						<p class="message__title">¡Hola!</p>
						<p class="message__subtitle">Para iniciar tu retiro escanea desde tu celular este código QR</p>
						<span class="message__arrow">
							<svg width="242" height="15" viewBox="0 0 242 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 6.49998C0.447715 6.49998 4.82823e-08 6.9477 0 7.49998C-4.82823e-08 8.05227 0.447715 8.49998 1 8.49998L1 6.49998ZM241.707 8.20711C242.098 7.81659 242.098 7.18342 241.707 6.7929L235.343 0.428937C234.953 0.038413 234.319 0.0384129 233.929 0.428937C233.538 0.819462 233.538 1.45263 233.929 1.84315L239.586 7.50001L233.929 13.1569C233.538 13.5474 233.538 14.1805 233.929 14.5711C234.319 14.9616 234.953 14.9616 235.343 14.5711L241.707 8.20711ZM1 8.49998L241 8.50001L241 6.50001L1 6.49998L1 8.49998Z" fill="white"/>
							</svg>
						</span>
					</div>
					<div class="qr">
						<div class="qr__image">
							<!-- <img src="../../../../assets/image/store_qr.png" alt="código qr"> -->
							<qrcode #parent
								[qrdata]="qrData"
								[width]="224"
								[errorCorrectionLevel]="'M'"
								[margin]="1"
								[elementType]="'img'"></qrcode> 
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<owl-carousel-o [options]="customOptions" *ngIf="orders.length > 5">
			<ng-template carouselSlide>
				<div class="content">
					<div class="content__user_list content__user_list--more_than_five" >
						<div class="item" [class.item--disabled]="order.status == 'blank'" *ngFor="let order of orders | slice:0:10; index as i">
							<div class="item__box">
								<span>{{i + 1}}</span>
							</div>
							<div class="item__user">
								<div class="item__user__name">
									<span>{{order.customerName}}</span>
								</div>
								<div class="item__user__status" [class.item__user__status--waiting]="order.status == 'pending'"></div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>  
			<ng-template carouselSlide *ngIf="orders.length > 10">
				<div class="content">
					<div class="content__user_list content__user_list--more_than_five">
						<div class="item" [class.item--disabled]="order.status == 'blank'" *ngFor="let order of orders.slice(10, 20); index as i">
							<div class="item__box">
								<span>{{i + 1 + 10}}</span>
							</div>
							<div class="item__user">
								<div class="item__user__name">
									<span>{{order.customerName}}</span>
								</div>
								<div class="item__user__status" [class.item__user__status--waiting]="order.status == 'pending'"></div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>  
			<ng-template carouselSlide *ngIf="orders.length > 20">
				<div class="content">
					<div class="content__user_list content__user_list--more_than_five">
						<div class="item" [class.item--disabled]="order.status == 'blank'" *ngFor="let order of orders.slice(20, 30); index as i">
							<div class="item__box">
								<span>{{i + 1 + 10 + 10}}</span>
							</div>
							<div class="item__user">
								<div class="item__user__name">
									<span>{{order.customerName}}</span>
								</div>
								<div class="item__user__status" [class.item__user__status--waiting]="order.status == 'pending'"></div>
							</div>
						</div>
					</div>
				</div>
			</ng-template> 
			<ng-template carouselSlide *ngIf="orders.length > 30">
				<div class="content">
					<div class="content__user_list content__user_list--more_than_five">
						<div class="item" *ngFor="let order of orders.slice(30, 40); index as i">
							<div class="item__box">
								<span>{{i + 1 + 10 + 10}}</span>
							</div>
							<div class="item__user">
								<div class="item__user__name">
									<span>{{order.customerName}}</span>
								</div>
								<div class="item__user__status" [class.item__user__status--waiting]="order.status == 'pending'"></div>
							</div>
						</div>
					</div>
				</div>
			</ng-template> 
		</owl-carousel-o>
	</div>

	<div class="pending_order_list__footer">
		<div class="pending_order_list__footer__container">
			<div class="leyend">
				<div class="leyend__attention">En atención <span class=""></span></div>
				<div class="leyend__wait">En espera <span ></span></div>
			</div>
			<div class="logo">
				<img src="../../../../assets/image/logo_oechsle_footer.png" alt="logo oechsle">
			</div>
		</div>
	</div>
</div>





<!-- 

<div fxLayout="row" fxLayoutAlign="center center" class="container">
	<mat-card fxFlex="96%">
		<div fxLayout="row">
			<div fxFlexOffset="1%" fxLayoutAlign="end center" class="back-{{companyCode}}">
					<img fxHide.xs class="icon-back" src="../../../../assets/image/back-{{companyCode}}.png" routerLink="/queue-manager">
					<div routerLink="/queue-manager" style="outline: none; padding-left: 15px;">Atrás</div>
			</div>
		</div>
		<div fxLayout="row">
			<table mat-table [dataSource]="[ 0, 1, 2, 3, 4, 5 ]" fxFlexOffset="2%" fxFlex="98%" class="table-progress">
				<ng-container matColumnDef="position">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" class="row-first-child" width="5%"> {{ element + 1 }}. </td>
				</ng-container>
	
				<ng-container matColumnDef="customer">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" width="47%"> {{ orderAttentionsProgress[element] ? orderAttentionsProgress[element].customerName : '' }} </td>
				</ng-container>
				
				<ng-container matColumnDef="service">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" class="row-last-child" width="48%"> {{ orderAttentionsProgress[element] ? orderAttentionsProgress[element].service : '' }} </td>
				</ng-container>
	
				<ng-container matColumnDef="header-group">
					<th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Pasan a ser atendidos:</th>
				</ng-container>
	
				<tr mat-header-row *matHeaderRowDef="['header-group']"></tr>
				<tr mat-header-row *matHeaderRowDef="displayedColumns" class="row-hidden"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>

		<div fxLayout="row" [style.margin-top]="'30px'">
			<table mat-table [dataSource]="[ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]" fxFlexOffset="2%" fxFlex="98%" class="table-pending">
				<ng-container matColumnDef="position">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" class="row-first-child" width="5%"> {{ element + 6 }}. </td>
				</ng-container>

				<ng-container matColumnDef="customer">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" width="47%"> {{ orderAttentionsPending[element] ? orderAttentionsPending[element].customerName : '' }} </td>
				</ng-container>
				
				<ng-container matColumnDef="service">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element" class="row-last-child" width="48%"> {{ orderAttentionsPending[element] ? orderAttentionsPending[element].service : '' }} </td>
				</ng-container>

				<ng-container matColumnDef="header-group">
					<th mat-header-cell *matHeaderCellDef [attr.colspan]="3">En espera:</th>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="['header-group']"></tr>
				<tr mat-header-row *matHeaderRowDef="displayedColumns" class="row-hidden"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>
	</mat-card>
</div> -->