import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  tenantId: string;
  storeCode: string;
  buttonState = true;
  documentNumberVal = '';
  documentType = '';
  document: any;
  path: string;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void  {
    this.tenantId = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.path = this.route.snapshot.paramMap.get('path');
  }

  login(documentNumber: any) {
    localStorage.setItem('documentNumber', documentNumber);
    this.router.navigate([`withdraws/orders/${this.tenantId}/${this.storeCode}/${this.path}`]);
  }

  validateLength(documentNumber: string) {
    const input = <HTMLInputElement>(document.getElementById('document_number'));
    this.documentNumberVal = this.limitCharacters(12, documentNumber).toUpperCase();
    this.buttonState = (/^[0-9]{8,12}$/.test(this.documentNumberVal)) ? false : true;
    if(!this.buttonState){
      this.buttonState = (/^[0-9]{10}$/.test(this.documentNumberVal)) ? true : false;
    }
  }

  resetInput() {
    this.documentNumberVal = '';
    this.buttonState = true;
  }

  limitCharacters(limitLength, value) {
    let limit = value.toString();
    if (limit.length > limitLength) limit = limit.substring(0, limitLength)
    return limit;
  }

  enterNumber(event) {
    if (event.target.getAttribute('data-attribute') === null || event.target.getAttribute('data-attribute') === 'null') {
      this.documentNumberVal = this.documentNumberVal.slice(0, -1);
    } else {
      this.documentNumberVal += event.target.getAttribute('data-attribute');
    }
    this.validateLength(this.documentNumberVal);
  }

  keyPressOnlyNumber(event) {​​​​​
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {​​​​​
      return true;
    }​​​​​ else {​​​​​
      event.preventDefault();
      return false;
    }
  ​}​​​​​

  back(){
    if(this.path == "0") {
      this.router.navigate([`/withdraws/welcome/${this.tenantId}/${this.storeCode}/${this.path}`]);
    } else if(this.path == "1") {
      this.router.navigate([`/withdraws/option/${this.tenantId}/${this.storeCode}/${this.path}`]);
    }
  }

}
