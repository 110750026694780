import { Component, Input, OnInit } from '@angular/core';
import { ScreenService } from 'src/app/util/screen.service';
import { OrderMobile } from '../../models/models';

@Component({
  selector: 'app-order-card-mobile',
  templateUrl: './order-card-mobile.component.html',
  styleUrls: ['./order-card-mobile.component.scss']
})
export class OrderCardMobileComponent implements OnInit {

  @Input() order:OrderMobile;

  panelOpenState = false;
  
  allSelected: boolean = false;
  constructor(private screen: ScreenService) { }

  ngOnInit(): void {
  }

  isScreenSmall(): boolean {
    return this.screen.sizes['screen-x-small'];
  }

  setAll(selected: boolean) {
    this.order.selected = selected;
    if (this.order.products == null) {
      return;
    }
    this.order.products.forEach(t => t.selected = selected);
  }

  updateAllSelected() {
    this.order.selected = this.order.products != null && this.order.products.every(t => t.selected);
  }

  someComplete(): boolean {
    if (this.order.products == null) {
      this.order.indeterminate = false;
      return false;
    }
    const result =  this.order.products.filter(t => t.selected).length > 0 && !this.order.selected;
    this.order.indeterminate = result;
    return result;
  }
}
