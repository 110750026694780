<div class="px-3 py-2 container__header">
    <div class="flex flex-row">
        <img src="./assets/icon/arrow_back.svg" (click)="redirectTo()">
        <div class="container__header__title">
            <div class="subtitle pr-1">Tda. {{ storeName }}</div>
            <img [src]="iconStorePath" width="24" height="20">
        </div>
    </div>
    <div class="flex flex-row items-center justify-center pt-2" *ngIf="withdrawalModality">
        <img [src]="withdrawalModality.icon" class="mr-2"/>
        <span style="font-size: 16px;
        line-height: 1;
        text-align: left;
        color: #416958;">{{ withdrawalModality.value }}</span>
    </div>
</div>