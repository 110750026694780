import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { OrderRetired } from '../../models/models';
import { ModalWithdrawalValidatedComponent } from '../modal-withdrawal-validated/modal-withdrawal-validated.component';

@Component({
  selector: 'app-validated-order-mobile',
  templateUrl: './validated-order-mobile.component.html',
  styleUrls: ['./validated-order-mobile.component.scss']
})
export class ValidatedOrderMobileComponent implements OnInit {

  @Input() storeCode: string;
  @Input() documentNumber: string;
  @Input() companyCode: string;

  sectionBody: string = 'loading';
  orders: OrderRetired[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private orderService: OrderService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getOrdersRetired();
    const queryParamSaleNotes: string = this.activatedRoute.snapshot.queryParamMap.get('saleNotes');
    const localStorageSaleNotes: string = localStorage.getItem('saleNotes');
    if (queryParamSaleNotes && localStorageSaleNotes && queryParamSaleNotes === localStorageSaleNotes) {
      this.openModal(queryParamSaleNotes);
      localStorage.removeItem('saleNotes');
    }
  }

  getOrdersRetired() {
    this.sectionBody = 'loading';

    this.orderService.getOrdersRetired(this.documentNumber, this.companyCode, this.storeCode).subscribe(response => {
      const listOrderRetiredMapper = this.mapper(response);
      if (listOrderRetiredMapper && listOrderRetiredMapper.length > 0) {
        this.sectionBody = 'body';
        const data: OrderRetired[] = listOrderRetiredMapper;
        this.orders = data;
      } else {
        this.sectionBody = 'sucessfull_empty';
      }
    }, error => {
      this.sectionBody = 'sucessfull_empty';
    });
  }

  mapper(data: OrderRetired[]): OrderRetired[] {
    let mapperData: OrderRetired[] = [];
    for (const order of data) {
      if (order.withdrawalValidated) {
        const quantity = order.products.filter(product => product.deliveredQuantity !== null).length;
        if (quantity > 0) {
          const productsRetired = order.products.filter(product => product.deliveredQuantity !== null);
          order.products = productsRetired;
          mapperData.push(order);
        } else {
          console.log('picking boy no retiro el pedido: ', order.products);
        }
      }
    }
    return mapperData;
  }

  openModal(saleNotes: string) {
    this.dialog.open(ModalWithdrawalValidatedComponent, {
      panelClass: 'modal-panel-cancel',
      maxWidth: '90vw',
      data: saleNotes,
      disableClose: true,
      position: {
        top: '100px'
      }
    });
  }
}
