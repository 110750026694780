import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { forkJoin } from 'rxjs';
import { commons } from 'src/environments/environment.commons';

export const url_orders: any[] = [
  {
    tenantId: 'hpsa',
    url: 'https://www.promart.pe/login?ReturnUrl=%2f_secure%2faccount#/orders',
  },
  {
    tenantId: 'tpsa',
    url: 'https://www.oechsle.pe/login?ReturnUrl=%2f_secure%2fmis-pedidos',
  },
  {
    tenantId: 'spsa',
    url: 'https://www.plazavea.com.pe/login?ReturnUrl=%2f_secure%2fmy-orders',
  },
  { tenantId: 'rp', url: 'https://www.realplaza.com/account#/mispedidos' },
];

function isValidated(element, index, array) {
  return element;
}

function isNotValidated(element, index, array) {
  return !element;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  companyCode: string;
  storeCode: string;
  documentNumber: string;

  loading: boolean = false;
  menu: any[] = [];

  configMenu: any[] = [
    {
      tenants: ['hpsa'],
      options: [
        {
          id: 'ORDERS',
          name: 'Modalidad de entrega',
          submenu: [
            {
              id: 'PENDING_ORDERS',
              icon: './assets/icon/menu__pickup_in_store.svg',
              iconDisabled: './assets/icon/menu__pickup_in_store_disabled.svg',
              name: 'Retiro en tienda',
              goTo: () => this.goToPendingOrders(),
              enabled: false,
            },
            {
              id: 'PENDING_ORDERS_AUTO',
              icon: './assets/icon/menu__withdrawal_at_auto_in_store.svg',
              iconDisabled:
                './assets/icon/menu__withdrawal_at_auto_in_store_disabled.svg',
              name: 'Retiro al auto',
              goTo: () => this.goToPendingOrdersAuto(),
              enabled: false,
            },
          ],
        },
        {
          id: 'ACTIONS',
          name: 'Acciones',
          submenu: [
            {
              id: 'WITHDRAWN_ORDERS',
              icon: './assets/icon/menu__withdrawn_orders.svg',
              iconDisabled: './assets/icon/menu__withdrawn_orders_disabled.svg',
              name: 'Valida la entrega tu pedido a la salida de tienda',
              goTo: () => this.goToWithdrawnOrders(),
              enabled: false,
            },
            {
              id: 'VALIDATED_ORDERS',
              icon: './assets/icon/menu__validated_orders.svg',
              iconDisabled: './assets/icon/menu__validated_orders_disabled.svg',
              name: 'Revisa tus pedidos validados',
              goTo: () => this.goToValidatedOrders(),
              enabled: false,
            },
            {
              id: 'WEB_ORDERS',
              icon: './assets/icon/menu__orders.svg',
              name: 'Consulta el detalle de tus pedidos',
              goTo: () => this.goToOrdes(),
              enabled: true,
            },
          ],
        },
      ],
    },
    {
      tenants: ['tpsa', 'spsa'],
      options: [
        {
          id: 'ORDERS',
          name: 'Modalidad de entrega',
          submenu: [
            {
              id: 'PENDING_ORDERS',
              icon: './assets/icon/menu__pickup_in_store.svg',
              iconDisabled: './assets/icon/menu__pickup_in_store_disabled.svg',
              name: 'Retiro en tienda',
              goTo: () => this.goToPendingOrders(),
              enabled: false,
            },
          ],
        },
        {
          id: 'ACTIONS',
          name: 'Acciones',
          submenu: [
            {
              id: 'WITHDRAWN_ORDERS',
              icon: './assets/icon/menu__withdrawn_orders.svg',
              iconDisabled: './assets/icon/menu__withdrawn_orders_disabled.svg',
              name: 'Valida la entrega tu pedido a la salida de tienda',
              goTo: () => this.goToWithdrawnOrders(),
              enabled: false,
            },
            {
              id: 'VALIDATED_ORDERS',
              icon: './assets/icon/menu__validated_orders.svg',
              iconDisabled: './assets/icon/menu__validated_orders_disabled.svg',
              name: 'Revisa tus pedidos validados',
              goTo: () => this.goToValidatedOrders(),
              enabled: false,
            },
            {
              id: 'WEB_ORDERS',
              icon: './assets/icon/menu__orders.svg',
              name: 'Consulta el detalle de tus pedidos',
              goTo: () => this.goToOrdes(),
              enabled: true,
            },
          ],
        },
      ],
    },

    {
      tenants: ['rp'],
      options: [
        {
          id: 'ORDERS',
          name: 'Modalidad de entrega',
          submenu: [
            {
              id: 'PENDING_ORDERS',
              icon: './assets/icon/menu__pickup_in_store.svg',
              iconDisabled: './assets/icon/menu__pickup_in_store_disabled.svg',
              name: 'Retira en tienda',
              goTo: () => this.goToPendingOrders(),
              enabled: false,
            },
          ],
        },
        {
          id: 'ACTIONS',
          name: 'Acciones',
          submenu: [
            {
              id: 'WITHDRAWN_ORDERS',
              icon: './assets/icon/menu__validated_orders.svg',
              iconDisabled: './assets/icon/menu__validated_orders_disabled.svg',
              name: 'Revisa tus pedidos retirados',
              goTo: () => this.goToWithdrawnOrders(),
              enabled: false,
            },
            {
              id: 'WEB_ORDERS',
              icon: './assets/icon/menu__orders.svg',
              name: 'Consulta el detalle de tus pedidos',
              goTo: () => this.goToOrdes(),
              enabled: true,
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.companyCode = this.route.snapshot.paramMap.get('companyCode');
    this.storeCode = this.route.snapshot.paramMap.get('storeCode');
    this.documentNumber = this.route.snapshot.paramMap.get('documentNumber');
    this.menu = this.configMenu.find((config) =>
      config.tenants.includes(this.companyCode)
    ).options;
    this.loadingMenu();
  }

  loadingMenu() {
    this.loading = true;
    const orderPending$ = this.orderService.getOrdersMobile(
      this.documentNumber,
      this.companyCode,
      this.storeCode
    );

    const orderRetired$ = this.orderService.getOrdersRetired(
      this.documentNumber,
      this.companyCode,
      this.storeCode
    );

    forkJoin([orderPending$, orderRetired$]).subscribe(
      (results) => {
        const listOrderPending = results[0];
        const listOrderRetired = results[1];
        if (listOrderPending && listOrderPending.length > 0) {
          //PENDING_ORDERS_AUTO
          let optionPending = this.menu
            .find((menu) => menu.id === 'ORDERS')
            ?.submenu?.find((option) => option.id === 'PENDING_ORDERS');

          let optionPendingAuto = this.menu
            .find((menu) => menu.id === 'ORDERS')
            ?.submenu?.find((option) => option.id === 'PENDING_ORDERS_AUTO');

          if (optionPending) {
            optionPending.enabled = true;
          }

          if(optionPendingAuto){
              for (let i = 0; i < commons.authorizedStoresPickupToCar.length; i++) {
                let objAuthorizedStoresPickupToCar = commons.authorizedStoresPickupToCar[i];
                if (this.companyCode == objAuthorizedStoresPickupToCar.tenantId) {
                  optionPendingAuto.enabled = objAuthorizedStoresPickupToCar.stores.includes(this.storeCode);
                }
              }
            }
         }

        if (listOrderRetired && listOrderRetired.length > 0) {
          let optionWithdrawn = this.menu
            .find((menu) => menu.id === 'ACTIONS')
            ?.submenu?.find((option) => option.id === 'WITHDRAWN_ORDERS');
          let optionValidated = this.menu
            .find((menu) => menu.id === 'ACTIONS')
            ?.submenu?.find((option) => option.id === 'VALIDATED_ORDERS');

          if (optionWithdrawn) {
            optionWithdrawn.enabled = listOrderRetired
              .map((orderRetired) => orderRetired.withdrawalValidated)
              .some(isNotValidated);
          }

          if (optionValidated) {
            optionValidated.enabled = listOrderRetired
              .map((orderRetired) => orderRetired.withdrawalValidated)
              .some(isValidated);
          }
        }

        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  goToPendingOrders() {
    this.router.navigate([
      `/withdraws/mobile/order-list-pending/${this.companyCode}/${this.storeCode}/${this.documentNumber}/4`,
    ]);
  }

  goToPendingOrdersAuto() {
    this.router.navigate([
      `/withdraws/mobile/order-list-pending/${this.companyCode}/${this.storeCode}/${this.documentNumber}/8`,
    ]);
  }

  goToWithdrawnOrders() {
    this.router.navigate([
      `/withdraws/mobile/order-list-retired/${this.companyCode}/${this.storeCode}/${this.documentNumber}`,
    ]);
  }

  goToValidatedOrders() {
    this.router.navigate([
      `/withdraws/mobile/order-list-validated/${this.companyCode}/${this.storeCode}/${this.documentNumber}`,
    ]);
  }

  goToOrdes() {
    let url = url_orders.find(
      (order) => order.tenantId === this.companyCode
    ).url;
    if (url) {
      window.open(url, '_blank');
    }
  }

  goHome() {
    this.router.navigate([
      `/withdraws/mobile/${this.companyCode}/${this.storeCode}`,
    ]);
  }

  getTooltipText(item){
    if((item.id === 'PENDING_ORDERS' || item.id === 'PENDING_ORDERS_AUTO') && !item.enabled){
      return 'Aún no tienes pedidos \n pendientes de recojo.';
    }
    else{
      return null;
    }
  }
}
