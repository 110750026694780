<div class="welcomeContainer">
    <div class="welcomeBody {{tenantId}}">
        <div class="welcomeCard">
            <span class="title">Bienvenido</span>
            <p class="message mt-6 mb-0">Para iniciar con el proceso de retiro escanea<br/>desde tu celular el siguiente código QR:</p>
            <div class="qr_container">
                <qrcode #parent
                    [qrdata]="qrData"
                    [width]="312"
                    [errorCorrectionLevel]="'M'"
                    [margin]="1"
                    [elementType]="'img'"></qrcode>
            </div>
        </div>
        <div class="enterButton_container {{tenantId}}">
            <span class="message">O también puedes ingresar desde aquí:</span>
            <button mat-raised-button class="btn-outline-primary-v3" (click)="enter()">
                INGRESAR
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
</div>